/*
Author       : Dreamguys
Template Name: Preskool - Bootstrap Admin Template
Version      : 1.0
*/

/*============================
 [Table of CSS]

1. General
2. Table
3. Helper Class
4. Bootstrap Classes
5. Select2
6. Nav Tabs
7. Components
8. Header
9. Footer
10. Sidebar
11. Content
12. Login
13. Notifications
14. Dashboard
15. Student Details
16. Event
17. Inbox
18. Error
19. Profile
20. Responsive
21. Blog
22. Blog Details
22. Custom
========================================*/

/*-----------------
	1. General
-----------------------*/
html {
	height: 100%;
}
body {
	background-color: #fff;
	color: #333;
	font-family: 'Roboto', sans-serif;
	font-size: 1rem;
	height: 100%;
	line-height: 1.5;
	overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Roboto', sans-serif;
	margin-top: 0;
}
a:hover,
a:active,
a:focus {
	outline: none;
	text-decoration: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0 1000px white inset !important;
	box-shadow: 0 0 0 1000px white inset !important;
}
.form-control {
    border: 1px solid #ddd;
    box-shadow: none;
    color: #333;
    font-size: 15px;
    height: 40px;
}
.form-control:focus {
	border-color: #FFBC53;
	box-shadow: none;
	outline: 0 none;
}
.form-control.form-control-sm {
	height: calc(1.5em + .5rem + 2px);
}
.form-control-sm {
	background-color: #fff;
}

.form-control.form-control-lg {
	height: calc(1.5em + 1rem + 2px);
}
a {
	color: #009ce7;
	text-decoration: none;
}
input,
button,
a {
	/* transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease; */
}
input, input:focus,
button, button:focus {
	outline: none;
}
input[type="file"] {
    height: auto;
    min-height: calc(1.5em + .75rem + 2px);
}
input[type=text],
input[type=password] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
textarea.form-control {
	resize: vertical;
	min-height: 120px;
}
.navbar-nav > li {
	float: left;
}
.form-group {
	margin-bottom: 1.25rem;
}
.input-group .form-control {
	height: 40px;
}
.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
	background-color: rgba(0, 0, 0, 0.1);
	border-color: rgba(0, 0, 0, 0.1);
}
.font-weight-600 {
	font-weight: 600;
}
/* ul, ol {
	padding: 0;
	list-style: none;
} */
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
label.custom-control-label {
    color: #dc3545;
}

/*-----------------
	2. Table
-----------------------*/

.table>:not(:first-child) {
    border-top: 1px solid currentColor;
}
.table {
	color: #333;
	max-width: 100%;
	margin-bottom: 0;
	width: 100%;
}
.table-striped > tbody > tr:nth-of-type(2n+1) {
	background-color: #f8f9fa;
}
.table.no-border > tbody > tr > td,
.table > tbody > tr > th,
.table.no-border > tfoot > tr > td,
.table.no-border > tfoot > tr > th,
.table.no-border > thead > tr > td,
.table.no-border > thead > tr > th {
	border-top: 0;
	padding: 10px 8px;
}
.table-nowrap td,
.table-nowrap th {
	white-space: nowrap
}
.table.dataTable {
	border-collapse: collapse !important;
	border: 1px solid rgba(0, 0, 0, 0.05);
}
table.table td h2 {
	display: inline-block;
	font-size: inherit;
	font-weight: 400;
	margin: 0;
	padding: 0;
	vertical-align: middle;
}
table.table td h2.table-avatar {
    align-items: center;
    display: inline-flex;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
	white-space: nowrap;
}
table.table td h2 a {
	color: #333;
}
table.table td h2 a:hover {
	color: #FFBC53;
}
table.table td h2 span {
	color: #888;
	display: block;
	font-size: 12px;
	margin-top: 3px;
}
.table thead tr th {
	font-weight: 600;
}
.table tbody tr {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.table.table-center td,
.table.table-center th {
	vertical-align: middle;
}
.table-hover tbody tr:hover {
	background-color: #f7f7f7;
}
.table-hover tbody tr:hover td {
	color: #474648;
}
.table-striped thead tr {
	border-color: transparent;
}
.table-striped tbody tr {
	border-color: transparent;
}
.table-striped tbody tr:nth-of-type(even) {
	background-color: rgba(255, 255, 255, 0.3);
}
.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(235, 235, 235, 0.4);
}
.table-bordered {
	border: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.table-bordered th,
.table-bordered td {
	border-color: rgba(0, 0, 0, 0.05);
}
.card-table .card-body .table > thead > tr > th {
    border-top: 0;
}
.card-table .card-body .table tr td:first-child,
.card-table .card-body .table tr th:first-child {
    padding-left: 1.5rem;
}
.card-table .card-body .table tr td:last-child,
.card-table .card-body .table tr th:last-child {
    padding-right: 1.5rem;
}
.card-table .table td, .card-table .table th {
    border-top: 1px solid #e2e5e8;
    padding: 1rem 0.75rem;
    white-space: nowrap;
}
.table .thead-light th {
    color: #495057;
    background-color: #f8f9fa;
    border-color: #eff2f7;
}
.data-table-extensions > .data-table-extensions-action > button.print {
	display: none;
}
.data-table-extensions > .data-table-extensions-action > button.download {
	background-image: url('../img/download-icon.png');
	border: 1px solid #FFBC53;
	position: absolute;
    right: -15px;
}
.data-table-extensions > .data-table-extensions-filter > .icon {
	display: none;
}
.data-table-extensions > .data-table-extensions-filter > .filter-text {
	border: 1px solid #dfdfdf;
	margin-left: -4px;
}
.data-table-extensions > .data-table-extensions-action > button.download:hover::after {
	content: '';
}
.user-dt a {
	color: #000;
}
.user-dt {
	align-items: center !important;
	display: inline-flex;
}
.iDzGLV {
	padding: 15px 10px;
}
.rdt_TableCol {
	font-size: 14px !important;
	font-weight: 500;
}
.rdt_TableCell {
	font-size: 14px !important;
}
.rdt_TableCell:nth-child(2), .rdt_TableCol:nth-child(2) {
	min-width: 200px !important;
	padding: 1rem 0.75rem;
}
.rdt_Pagination {
	display: flex !important;
    justify-content: start !important;
}
.jpRntR {
	position: absolute !important;
    right: 30px !important;
}
.react-calendar {
	width: 100%;
}
.react-calendar__tile {
	padding: 30px 20px;
	border: 1px solid #f1f1f1 !important;
}
.react-calendar__tile--now {
    background: #fcba38;
    color: #fff;
}
.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
    background: #18aefa;
}
.react-calendar__month-view__weekdays__weekday {
    padding: 15px;
    background-color: #18aefa;
    color: #fff;
    border: 1px solid #0ca4f1;
}
.react-calendar__navigation button:enabled {
	font-size: 22px;
	font-weight: 600;
}


/*-----------------
	3. Helper Class
-----------------------*/

.p-20 {
	padding: 20px !important;
}
.p-t-0 {
	padding-top: 0 !important;
}
.m-0 {
	margin: 0 !important;
}
.m-r-5 {
	margin-right: 5px !important;
}
.m-r-10 {
	margin-right: 10px !important;
}
.m-l-5 {
	margin-left: 5px !important;
}
.m-l-15 {
	margin-left: 15px !important;
}
.m-t-5 {
	margin-top: 5px !important;
}
.m-t-0 {
	margin-top: 0 !important;
}
.m-t-10 {
	margin-top: 10px !important;
}
.m-t-15 {
	margin-top: 15px !important;
}
.m-t-20 {
	margin-top: 20px !important;
}
.m-t-30 {
	margin-top: 30px !important;
}
.m-t-50 {
	margin-top: 50px !important;
}
.m-b-5 {
	margin-bottom: 5px !important;
}
.m-b-10 {
	margin-bottom: 10px !important;
}
.m-b-15 {
	margin-bottom: 15px !important;
}
.m-b-20 {
	margin-bottom: 20px !important;
}
.m-b-30 {
	margin-bottom: 30px !important;
}
.block {
	display: block !important;
}

/*-----------------
	4. Bootstrap Classes
-----------------------*/

.btn.focus, .btn:focus {
    box-shadow: unset;
}
.btn-white {
	background-color: #fff;
	border: 1px solid #ccc;
	color: #333;
}
.btn.btn-rounded {
	border-radius: 50px;
}
.bg-primary {
	background-color: #fff5d9 !important;
}
.pro-bg-primary, .badge-primary {
	background-color: #fdbb38 !important;
}
.bg-light {
    background-color: #ebebff!important;
}
a.bg-primary:focus, 
a.bg-primary:hover, 
button.bg-primary:focus, 
button.bg-primary:hover {
    background-color: #18aefa !important;
}
.bg-success,
.badge-success {
	background-color: #7bb13c !important;
}
a.bg-success:focus, 
a.bg-success:hover, 
button.bg-success:focus, 
button.bg-success:hover {
    background-color: #699834 !important;
}
.bg-info,
.badge-info {
	background-color: #009efb !important;
}
.pro-bg-info, .badge-info {
	background-color: #56dccc !important;
}
.pro-bg-light, .badge-light{
	background-color: #18aefa !important;
}
a.bg-info:focus, 
a.bg-info:hover, 
button.bg-info:focus, 
button.bg-info:hover {
    background-color: #028ee1 !important;
}
.bg-warning,
.badge-warning {
	background-color: #ffbc34 !important;
}
a.bg-warning:focus, 
a.bg-warning:hover, 
button.bg-warning:focus, 
button.bg-warning:hover {
    background-color: #e9ab2e !important;
}
.bg-danger,
.badge-danger {
	background-color: #e84646 !important;
}
a.bg-danger:focus, 
a.bg-danger:hover, 
button.bg-danger:focus, 
button.bg-danger:hover {
    background-color: #e63333 !important;
}
.bg-white {
	background-color: #fff;
}
.bg-purple,
.badge-purple {
    background-color: #9368e9 !important;
}
.text-primary,
.dropdown-menu > li > a.text-primary {
	color: #FFBC53 !important;
}
.text-success,
.dropdown-menu > li > a.text-success {
	color: #699834 !important;
}
.text-danger,
.dropdown-menu > li > a.text-danger {
	color: #e84646 !important;
}
.text-info,
.dropdown-menu > li > a.text-info {
	color: #009efb !important;
}
.text-warning,
.dropdown-menu > li > a.text-warning {
	color: #ffbc34 !important;
}
.text-purple,
.dropdown-menu > li > a.text-purple {
	color: #7460ee !important;
}
.text-muted {
    color: #757575 !important;
}
.btn-primary {
	background-color: #FFBC53;
	border: 1px solid #FFBC53;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
	background-color: #18aefa;
	border: 1px solid #18aefa;
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
	background-color: #18aefa;
	border: 1px solid #18aefa;
}
.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle {
	background-color: #18aefa;
	border-color: #18aefa;
	color: #fff;
}
.btn-primary.active:focus:not(:disabled):not(.disabled), 
.btn-primary:active:focus:not(:disabled):not(.disabled), 
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-primary.disabled, .btn-primary:disabled {
    background-color: #FFBC53;
    border-color: #FFBC53;
    color: #fff;
}
.btn-secondary.active:focus:not(:disabled):not(.disabled), 
.btn-secondary:active:focus:not(:disabled):not(.disabled), 
.show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-success {
	background-color: #7bb13c;
	border: 1px solid #7bb13c
}
.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open > .dropdown-toggle.btn-success {
	background-color: #699834;
	border: 1px solid #699834;
	color: #fff;
}
.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
	background-color: #699834;
	border: 1px solid #699834
}
.btn-success.active:not(:disabled):not(.disabled),
.btn-success:active:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle {
	background-color: #699834;
	border-color: #699834;
	color: #fff;
}
.btn-success.active:focus:not(:disabled):not(.disabled), 
.btn-success:active:focus:not(:disabled):not(.disabled), 
.show > .btn-success.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-success.disabled, .btn-success:disabled {
    background-color: #7bb13c;
    border-color: #7bb13c;
    color: #fff;
}
.btn-info {
	background-color: #009efb;
	border: 1px solid #009efb
}
.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
	background-color: #028ee1;
	border: 1px solid #028ee1
}
.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
	background-color: #028ee1;
	border: 1px solid #028ee1
}
.btn-info.active:not(:disabled):not(.disabled),
.btn-info:active:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle {
	background-color: #028ee1;
	border-color: #028ee1;
	color: #fff;
}
.btn-info.active:focus:not(:disabled):not(.disabled), 
.btn-info:active:focus:not(:disabled):not(.disabled), 
.show > .btn-info.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-info.disabled, .btn-info:disabled {
    background-color: #009efb;
    border-color: #009efb;
    color: #fff;
}
.btn-warning {
	background-color: #ffbc34;
	border: 1px solid #ffbc34
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open > .dropdown-toggle.btn-warning {
	background-color: #e9ab2e;
	border: 1px solid #e9ab2e
}
.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
	background-color: #e9ab2e;
	border: 1px solid #e9ab2e
}
.btn-warning.active:not(:disabled):not(.disabled),
.btn-warning:active:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle {
	background-color: #e9ab2e;
	border-color: #e9ab2e;
	color: #fff;
}
.btn-warning.active:focus:not(:disabled):not(.disabled), 
.btn-warning:active:focus:not(:disabled):not(.disabled), 
.show > .btn-warning.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-warning.disabled, .btn-warning:disabled {
    background-color: #ffbc34;
    border-color: #ffbc34;
    color: #fff;
}
.btn-danger {
	background-color: #e84646;
	border: 1px solid #e84646;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
	background-color: #e63333;
	border: 1px solid #e63333;
}
.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
	background-color: #e63333;
	border: 1px solid #e63333;
}
.btn-danger.active:not(:disabled):not(.disabled),
.btn-danger:active:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle {
	background-color: #e63333;
	border-color: #e63333;
	color: #fff;
}
.btn-danger.active:focus:not(:disabled):not(.disabled), 
.btn-danger:active:focus:not(:disabled):not(.disabled), 
.show > .btn-danger.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-danger.disabled, .btn-danger:disabled {
    background-color: #f62d51;
    border-color: #f62d51;
    color: #fff;
}
.btn-light.active:focus:not(:disabled):not(.disabled), 
.btn-light:active:focus:not(:disabled):not(.disabled), 
.show > .btn-light.dropdown-toggle:focus {
    box-shadow: unset;
}
.btn-dark.active:focus:not(:disabled):not(.disabled), 
.btn-dark:active:focus:not(:disabled):not(.disabled), 
.show > .btn-dark.dropdown-toggle:focus {
    box-shadow: unset;
}
.btn-outline-primary {
	color: #FFBC53;
	border-color: #FFBC53;

}

.row .btn-outline-secondary {	
	width: 100%;
}
.row .btn-outline-success {	
	width: 100%;
}
.row .btn-outline-warning{	
	width: 100%;
}
.row .btn-outline-danger {	
	width: 100%;
}
.row .btn-outline-info{	
	width: 100%;
}
.row .btn-outline-light {	
	width: 100%;
}
.row .btn-outline-dark {	
	width: 100%;
}
.btn-outline-primary:hover {
	background-color: #FFBC53;
	border-color: #FFBC53;
	
}
.btn-outline-primary:focus, 
.btn-outline-primary.focus {
	box-shadow: none;
}
.btn-outline-primary.disabled, 
.btn-outline-primary:disabled {
	color: #FFBC53;
	background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, 
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
	background-color: #FFBC53;
	border-color: #FFBC53;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, 
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-success {
	color: #7bb13c;
	border-color: #7bb13c;
}
.btn-outline-success:hover {
	background-color: #7bb13c;
	border-color: #7bb13c;
}
.btn-outline-success:focus, .btn-outline-success.focus {
	box-shadow: none;
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
	color: #7bb13c;
	background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, 
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
	background-color: #7bb13c;
	border-color: #7bb13c;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, 
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-info {
	color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:hover {
	color: #fff;
	background-color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:focus, .btn-outline-info.focus {
	box-shadow: none;
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
	background-color: transparent;
	color: #009efb;
}
.btn-outline-info:not(:disabled):not(.disabled):active, 
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
	background-color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, 
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-warning {
	color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:hover {
	color: #212529;
	background-color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
	box-shadow: none;
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
	background-color: transparent;
	color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, 
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
	color: #212529;
	background-color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, 
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-danger {
	color: #e84646;
	border-color: #e84646;
}
.btn-outline-danger:hover {
	color: #fff;
	background-color: #e84646;
	border-color: #e84646;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
	box-shadow: none;
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
	background-color: transparent;
	color: #e84646;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, 
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
	background-color: #e84646;
	border-color: #e84646;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, 
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-light {
    color: #ababab;
    border-color: #e6e6e6;
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #ababab;
}
.pagination > .active > a, 
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
	background-color: #FFBC53;
	border-color: #FFBC53;
}
.pagination > li > a, 
.pagination > li > span {
	color: #FFBC53;
}
.page-link:hover {
	color: #FFBC53;
}
.page-link:focus {
	box-shadow: unset;
}
.page-item.active .page-link {
	background-color: #FFBC53;
	border-color: #FFBC53;
}
.dropdown-menu {
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	transform-origin: left top 0;
	box-shadow: inherit;
	background-color: #fff;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: #FFBC53;
}
.navbar-nav .open .dropdown-menu {
	border: 0;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	background-color: #fff;
}
.dropdown-menu {
	font-size: 14px;
}
.card {
    border: 1px solid #ececf5;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.03);
    margin-bottom: 1.875rem;
}
.card-body {
	padding: 1.5rem;
}
.card-header {
	border-bottom: 1px solid #e6e6e6;
	padding: 1rem 1.5rem;
}
.card-footer {
	background-color: #fff;
	border-top: 1px solid #e6e6e6;
	padding: 1rem 1.5rem;
}
.card .card-header {
    background-color: #fff;
    border-bottom: 1px solid #eaeaea;
}
.card .card-header .card-title {
    margin-bottom: 0;
    font-size: 18px;
}
.modal-footer.text-left {
	text-align: left;
}
.modal-footer.text-center {
	text-align: center;
}
.btn-light {
	border-color: #e6e6e6;
	color: #a6a6a6;
}
.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
	background-color: #FFBC53;
	text-shadow: unset;
}
.bootstrap-datetimepicker-widget table td.today:before{
	border-bottom-color:#FFBC53;
}
.bg-info-light{
	background-color:rgba(2, 182, 179,0.12) !important;
	color:#1db9aa !important;
}
.bg-primary-light{
	background-color:rgba(17, 148, 247,0.12) !important;
	color:#2196f3 !important;
}
.bg-danger-light{
	background-color:#FFBC53 !important;
	color:#fff !important;
}
.bg-warning-light{
	background-color:rgba(255, 152, 0,0.12) !important;
	color:#f39c12 !important;
}
.bg-success-light{
	background-color: #18aefa;
    color: #fff;
}
.bg-purple-light{
	background-color:rgba(197, 128, 255,0.12) !important;
	color:#c580ff !important;
}
.bg-default-light{
	background-color:rgba(40, 52, 71,0.12) !important;
	color:#283447 !important;
}
.bg-success-light:hover {
	color: #fff;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
	margin: 15px 0 0;
}
div.dataTables_wrapper div.dataTables_info {
    padding-top: 25px;
}

/*-----------------
	5. Select2
-----------------------*/

.select2-container .select2-selection--single {
	border: 1px solid #ddd;
	height: 40px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 38px;
	right: 7px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #ddd transparent transparent;
	border-style: solid;
	border-width: 6px 6px 0;
	height: 0;
	left: 50%;
	margin-left: -10px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 0;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #ddd;
	border-width: 0 6px 6px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
	padding-right: 30px;
	padding-left: 15px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #333;
	font-size: 15px;
	font-weight: normal;
	line-height: 38px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #7638ff;
}
.select2-container--default .select2-selection--multiple {
	border: 1px solid #ddd;
	min-height: 40px;
}

/*-----------------
	6. Nav Tabs
-----------------------*/

.nav-tabs {
	border-bottom: 1px solid #e6e6e6;
}
.card-header-tabs {
    border-bottom: 0;
}
.nav-tabs > li > a {
	margin-right: 0;
	color: #888;
	border-radius: 0;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
	border-color: transparent;
	color: #333;
}
.nav-tabs.nav-tabs-solid > li > a {
	color: #333;
}
.nav-tabs.nav-tabs-solid > .active > a,
.nav-tabs.nav-tabs-solid > .active > a:hover,
.nav-tabs.nav-tabs-solid > .active > a:focus {
	background-color: #FFBC53;
	border-color: #FFBC53;
	color: #fff;
}
.tab-content {
	padding-top: 20px;
}
.nav-tabs .nav-link {
	border-radius: 0;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    background-color: #eee;
    border-color: transparent;
	color: #333;
}
.nav-tabs.nav-justified > li > a {
	border-radius: 0;
	margin-bottom: 0;
}
.nav-tabs.nav-justified > li > a:hover,
.nav-tabs.nav-justified > li > a:focus {
	border-bottom-color: #ddd;
}
.nav-tabs.nav-justified.nav-tabs-solid > li > a {
	border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a {
	color: #333;
}
.nav-tabs.nav-tabs-solid > li > a.active,
.nav-tabs.nav-tabs-solid > li > a.active:hover,
.nav-tabs.nav-tabs-solid > li > a.active:focus {
	background-color: #FFBC53;
	border-color: #FFBC53;
	color: #fff;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
	border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
	border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus {
	border-radius: 50px;
}
.nav-tabs-justified > li > a {
	border-radius: 0;
	margin-bottom: 0;
}
.nav-tabs-justified > li > a:hover,
.nav-tabs-justified > li > a:focus {
	border-bottom-color: #ddd;
}
.nav-tabs-justified.nav-tabs-solid > li > a {
	border-color: transparent;
}
.nav-tabs.nav-justified.nav-tabs-top {
	border-bottom: 1px solid #ddd;
}
.nav-tabs.nav-justified.nav-tabs-top > li > a,
.nav-tabs.nav-justified.nav-tabs-top > li > a:hover,
.nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li {
	margin-bottom: 0;
}
.nav-tabs.nav-tabs-top > li > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li.open > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
	border-top-color: #ddd;
}
.nav-tabs.nav-tabs-top > li+li > a {
	margin-left: 1px;
}
.nav-tabs.nav-tabs-top > li > a.active,
.nav-tabs.nav-tabs-top > li > a.active:hover,
.nav-tabs.nav-tabs-top > li > a.active:focus {
	border-top-color: #FFBC53;
}
.nav-tabs.nav-tabs-bottom > li {
	margin-bottom: -1px;
}
.nav-tabs.nav-tabs-bottom > li > a.active, 
.nav-tabs.nav-tabs-bottom > li > a.active:hover, 
.nav-tabs.nav-tabs-bottom > li > a.active:focus {
	border-bottom-width: 2px;
	border-color: transparent;
	border-bottom-color: #FFBC53;
	background-color: transparent;
	transition: none 0s ease 0s; 
	-moz-transition: none 0s ease 0s; 
	-o-transition: none 0s ease 0s; 
	-ms-transition: none 0s ease 0s; 
	-webkit-transition: none 0s ease 0s;
}
.nav-tabs.nav-tabs-solid {
	background-color: #fafafa;
	border: 0;
}
.nav-tabs.nav-tabs-solid > li {
	margin-bottom: 0;
}
.nav-tabs.nav-tabs-solid > li > a {
	border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a:hover,
.nav-tabs.nav-tabs-solid > li > a:focus {
	background-color: #f5f5f5;
}
.nav-tabs.nav-tabs-solid > .open:not(.active) > a {
	background-color: #f5f5f5;
	border-color: transparent;
}
.nav-tabs-justified.nav-tabs-top {
	border-bottom: 1px solid #ddd;
}
.nav-tabs-justified.nav-tabs-top > li > a,
.nav-tabs-justified.nav-tabs-top > li > a:hover,
.nav-tabs-justified.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}

/*-----------------
	7. Components
-----------------------*/

.btn-link {
    text-decoration: none;
}
.breadcrumb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}
.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit;
    border: none;
    background: transparent;
}
.section-header {
	margin-bottom: 1.875rem;
}
.section-header .section-title {
	color: #333;
}
.line {
	background-color: #18aefa;
	height: 2px;
	margin: 0;
	width: 60px;
}
.comp-buttons .btn {
	margin-bottom: 5px;
}
.pagination-box .pagination {
	margin-top: 0;
}
.comp-dropdowns .btn-group {
	margin-bottom: 5px;
}
.progress-example .progress {
	margin-bottom: 1.5rem;
}
.progress-xs {
	height: 4px;
}
.progress-sm {
	height: 15px;
}
.progress.progress-sm {
	height: 6px;
}
.progress.progress-md {
	height: 8px;
}
.progress.progress-lg {
	height: 18px;
}
.row.row-sm {
	margin-left: -3px;
	margin-right: -3px;
}
.row.row-sm > div {
	padding-left: 3px;
	padding-right: 3px;
}
.avatar {
	position: relative;
	display: inline-block;
	width: 3rem;
	height: 3rem
}
.avatar > img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}
.avatar-title {
	width: 100%;
	height: 100%;
	background-color: #FFBC53;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}
.avatar-away:before,
.avatar-offline:before,
.avatar-online:before {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 25%;
	height: 25%;
	border-radius: 50%;
	content: '';
	border: 2px solid #fff;
}
.avatar-online:before {
	background-color: #7bb13c;
}
.avatar-offline:before {
	background-color: #e84646;
}
.avatar-away:before {
	background-color: #ffbc34;
}
.avatar .border {
	border-width: 3px !important;
}
.avatar .rounded {
	border-radius: 6px !important;
}
.avatar .avatar-title {
	font-size: 18px;
}
.avatar-xs {
	width: 1.65rem;
	height: 1.65rem;
}
.avatar-xs .border {
	border-width: 2px !important;
}
.avatar-xs .rounded {
	border-radius: 4px !important;
}
.avatar-xs .avatar-title {
	font-size: 12px;
}
.avatar-xs.avatar-away:before,
.avatar-xs.avatar-offline:before,
.avatar-xs.avatar-online:before {
	border-width: 1px;
}
.avatar-sm {
	width: 2.5rem;
	height: 2.5rem;
}
.avatar-sm .border {
	border-width: 3px !important;
}
.avatar-sm .rounded {
	border-radius: 4px !important;
}
.avatar-sm .avatar-title {
	font-size: 15px;
}
.avatar-sm.avatar-away:before,
.avatar-sm.avatar-offline:before,
.avatar-sm.avatar-online:before {
	border-width: 2px;
}
.avatar-lg {
	width: 3.75rem;
	height: 3.75rem;
}
.avatar-lg .border {
	border-width: 3px !important;
}
.avatar-lg .rounded {
	border-radius: 8px !important;
}
.avatar-lg .avatar-title {
	font-size: 24px;
}
.avatar-lg.avatar-away:before,
.avatar-lg.avatar-offline:before,
.avatar-lg.avatar-online:before {
	border-width: 3px;
}
.avatar-xl {
	width: 5rem;
	height: 5rem;
}
.avatar-xl .border {
	border-width: 4px !important;
}
.avatar-xl .rounded {
	border-radius: 8px !important;
}
.avatar-xl .avatar-title {
	font-size: 28px;
}
.avatar-xl.avatar-away:before,
.avatar-xl.avatar-offline:before,
.avatar-xl.avatar-online:before {
	border-width: 4px;
}
.avatar-xxl {
	width: 5.125rem;
	height: 5.125rem;
}
.avatar-xxl .border {
	border-width: 6px !important;
}
.avatar-xxl .rounded {
	border-radius: 8px !important;
}
.avatar-xxl .avatar-title {
	font-size: 30px;
}
.avatar-xxl.avatar-away:before,
.avatar-xxl.avatar-offline:before,
.avatar-xxl.avatar-online:before {
	border-width: 4px;
}
.avatar-group {
	display: inline-flex;
}
.avatar-group .avatar + .avatar {
	margin-left: -.75rem;
}
.avatar-group .avatar-xs + .avatar-xs {
	margin-left: -.40625rem;
}
.avatar-group .avatar-sm+.avatar-sm {
	margin-left: -.625rem;
}
.avatar-group .avatar-lg + .avatar-lg {
	margin-left: -1rem;
}
.avatar-group .avatar-xl + .avatar-xl {
	margin-left: -1.28125rem;
}
.avatar-group .avatar:hover {
	z-index: 1;
}

/*-----------------
	8. Header
-----------------------*/

.header {
	background-color: #fff;
	border-bottom: 1px solid #f3f3f4;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1001;
	height: 60px;
}
.header .header-left {
	float: left;
    height: 60px;
    padding: 0 20px;
    position: relative;
    width: 320px;
    z-index: 1;
    /* transition: all 0.2s ease-in-out; */
    background-color: #f7f7fa;
    border-bottom: 1px solid #efefef;
	border-right: 1px solid #ececf5;
    display: flex;
    align-items: center;	
}
.mini-sidebar .header .header-left {
	justify-content: center;
}
.header .header-left .logo {
	display: inline-block;
	line-height: 60px;
}
.header .header-left .logo img {
    max-height: 40px;
    width: auto;
}
.header-left .logo.logo-small {
    display: none;
}
.header .dropdown-menu > li > a {
	position: relative;
}
.header .dropdown-toggle:after {
	display: none;
}
.header .has-arrow .dropdown-toggle:after {
	border-top: 0;
	border-left: 0;
	border-bottom: 2px solid #18aefa;
	border-right: 2px solid #18aefa;
	content: '';
	height: 8px;
	display: inline-block;
	pointer-events: none;
	-webkit-transform-origin: 66% 66%;
	-ms-transform-origin: 66% 66%;
	transform-origin: 66% 66%;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	/* -webkit-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out; */
	width: 8px;
	vertical-align: 0;
}
.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
	-webkit-transform: rotate(-135deg);
	-ms-transform: rotate(-135deg);
	transform: rotate(-135deg);
}
.user-menu {
    float: right;
    margin: 0;
    position: relative;
	z-index: 99;
	padding-right: 15px;
}
.user-menu.nav > li > a {
	color: #a0a0a0;
	font-size: 14px;
	line-height: 58px;
	padding: 0 15px;
	height: 60px;
}
.user-menu.nav > li > a:hover,
.user-menu.nav > li > a:focus {
	background-color: rgb(232 232 232 / 20%);
}
.user-menu.nav > li > a:hover i,
.user-menu.nav > li > a:focus i {
	color: #18aefa;
}
.user-img {
	margin-right: 3px;
	position: relative;
}
.user-menu.nav > li > a.mobile_btn {
	border: 0;
	position: relative;
	padding: 0;
	margin: 0;
	cursor: pointer
}
.user-menu .dropdown-menu {
    min-width: 200px;
    padding: 0;
}
.user-menu .dropdown-menu .dropdown-item {
    padding: 7px 15px;
}
.user-menu .dropdown-menu .dropdown-item {
    display: flex;
    align-items: center;
    border-top: 1px solid #e3e3e3;
    padding: 10px 15px;
}
.user-menu .dropdown-menu .dropdown-item:hover {
    color: #FFBC53;
}
.user-menu .dropdown .btn-primary {
	background: none;
	border: none;
	padding: 14px 5px;
}
.user-menu .dropdown .btn-primary svg {
	color: #a0a0a0;
    font-size: 24px;
    line-height: 30px;
    padding: 0;
    height: auto;
}
.user-menu .btn .badge {
    position: relative;
    top: -14px;
    background: #ffbc53;
    font-size: 10px;
    right: 12px;
    padding: 3px 5px;
}
.user-menu .btn-primary.active:not(:disabled):not(.disabled), .user-menu .btn-primary:active:not(:disabled):not(.disabled), .user-menu .show > .btn-primary.dropdown-toggle {
	background: transparent;
}


.header .dropdown-menu > li > a:focus,
.header .dropdown-menu > li > a:hover {
	background-color: #FFBC53;
	color: #fff;
}
.header .dropdown-menu > li > a:focus i,
.header .dropdown-menu > li > a:hover i {
	color: #fff;
}
.header .dropdown-menu > li > a {
	padding: 10px 18px;
}
.header .dropdown-menu > li > a i {
	color: #FFBC53;
	margin-right: 10px;
	text-align: center;
	width: 18px;
}
.header .user-menu .dropdown-menu > li > a i {
	color: #FFBC53;
	font-size: 16px;
	margin-right: 10px;
	min-width: 18px;
	text-align: center;
}
.header .user-menu .dropdown-menu > li > a:focus i,
.header .user-menu .dropdown-menu > li > a:hover i {
	color: #fff;
}
.mobile_btn {
	display: none;
	float: left;
}
.slide-nav .sidebar {
	margin-left: 0;
}
.user-header {
    background-color: #f9f9f9;
    display: flex;
    padding: 10px 15px;
}
.user-header .user-text {
	margin-left: 10px;
}
.user-header .user-text h6 {
    margin-bottom: 2px;
}
.menu-title {
    color: #a3a3a3;
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    padding: 0 25px;
}
.sidebar-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 60px;
    width: 100%;
    z-index: 1000;
}
.sidebar-overlay.opened {
    display: block;
}
html.menu-opened {
	overflow: hidden;
}
html.menu-opened body {
	overflow: hidden;
}
.top-nav-search {
    float: left;
    margin-left: 15px;
}
.top-nav-search form {
    margin-top: 10px;
	position: relative;
    width: 300px;
}
.top-nav-search .form-control {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    color: #ddd;
    height: 40px;
    padding: 10px 50px 10px 15px;
}
.top-nav-search .btn {
    background-color: transparent;
    border-color: transparent;
    color: #ffbc53;
    min-height: 40px;
    padding: 7px 15px;
    position: absolute;
    right: 0;
    top: 0;
}
.top-nav-search .form-control::-webkit-input-placeholder {
	color: #ddd;
}
.top-nav-search .form-control::-moz-placeholder {
	color: #ddd;
}
.top-nav-search .form-control:-ms-input-placeholder {
	color: #ddd;
}
.top-nav-search .form-control::-ms-input-placeholder {
	color: #ddd;
}
.top-nav-search .form-control::placeholder {
	color: #ddd;
}
.top-nav-search.active form {
    display: block;
    left: 0;
    position: absolute;
}

/*-----------------
	9. Footer
-----------------------*/

footer {
	padding: 1.875rem 1.875rem;
    border-top: 1px solid #ddd;
}
footer p {
	margin: 0;
}

/*-----------------
	10. Sidebar
-----------------------*/

.sidebar {
    background-color: #f7f7fa;
	border-right: 1px solid #ececf5;
    bottom: 0;
    left: 0;
    margin-top: 0;
    position: fixed;
    top: 60px;
    transition: all 0.2s ease-in-out 0s;
    width: 320px;
    z-index: 1001;	
}
.sidebar.opened {
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.sidebar-inner {
	height: 100%;
	min-height: 100%;
	transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu {
    padding: 15px;
}
.sidebar-menu ul {
	font-size: 15px;
	list-style-type: none;
	margin: 0;
	padding: 0;
	position: relative;
}
.sidebar-menu li a {
	color: #6f6f6f;
	display: block;
	font-size: 16px;
	height: auto;
	padding: 0 20px;
}
.sidebar-menu li a:hover {
    color: #18aefa;
}
.sidebar-menu > ul > li > a:hover {
	background-color: #fff;
    color: #18aefa; 
	margin: 0 -15px;
	padding-left: 30px;
}
.sidebar-menu li.active > a {
    background-color: #fff;
    color: #18aefa;
    position: relative;
    margin: 0 -15px;
    padding-left: 30px;
}
.sidebar-menu li.active > a::before {
    width: 5px;
    content: "";
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #18aefa;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
}
.sidebar-menu li.active ul li.active > a {
	background-color: transparent;
	margin: 0 15px;
}
.sidebar-menu li.active ul li.active > a::before {
	background: transparent;
}
.menu-title {
    color: #9e9e9e;
    display: flex;
    font-size: 14px;
    opacity: 1;
    padding: 5px 15px;
    white-space: nowrap;
}
.menu-title > i {
	float: right;
	line-height: 40px;
}
.sidebar-menu li.menu-title a {
    color: #ff9b44;
    display: inline-block;
    margin-left: auto;
    padding: 0;
}
.sidebar-menu li.menu-title a.btn {
    color: #fff;
    display: block;
    float: none;
    font-size: 15px;
    margin-bottom: 15px;
    padding: 10px 15px;
}
.sidebar-menu ul ul a.active {
	color: #18aefa;
}
.mobile_btn {
	display: none;
	float: left;
}
.sidebar .sidebar-menu > ul > li > a span {
	transition: all 0.2s ease-in-out 0s;
	display: inline-block;
	margin-left: 10px;
	white-space: nowrap;
}
.sidebar .sidebar-menu > ul > li > a span.chat-user {
    margin-left: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}
.sidebar .sidebar-menu > ul > li > a span.badge {
	margin-left: auto;
}
.sidebar-menu ul ul a {
    display: block;
    font-size: 15px;
    padding: 7px 10px 7px 45px;
    position: relative;
}
.sidebar-menu ul ul {
	display: none;
}
.sidebar-menu ul ul ul a {
	padding-left: 65px;
}
.sidebar-menu ul ul ul ul a {
	padding-left: 85px;
}
.sidebar-menu > ul > li {
    margin-bottom: 3px;
    position: relative;
}
.sidebar-menu > ul > li:last-child {
    margin-bottom: 0;
}
.sidebar-menu .menu-arrow {
	-webkit-transition: -webkit-transform 0.15s;
	-o-transition: -o-transform 0.15s;
	transition: transform .15s;
	position: absolute;
	right: 15px;
	display: inline-block;
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	text-rendering: auto;
	line-height: 40px;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
	line-height: 18px;
	top: 14px;
}
.sidebar-menu .menu-arrow:before {
	content: "\f105";
}
.sidebar-menu li a.subdrop .menu-arrow {
	-ms-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}
.sidebar-menu ul ul a .menu-arrow {
	top: 10px;
}
.sidebar-menu > ul > li > a {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 12px 15px;
    position: relative;
    transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu ul li a i {
	display: inline-block;
	font-size: 16px;
	line-height: 24px;
	text-align: left;
	vertical-align: middle;
	width: 20px;
	transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu ul li.menu-title a i {
	font-size: 16px !important;
	margin-right: 0;
	text-align: right;
	width: auto;
}
.sidebar-menu li a > .badge {
    color: #fff;
}

/*-----------------
	11. Content
-----------------------*/

.main-wrapper {
	width: 100%;
	height: 100vh;
	min-height: 100vh;
}
.page-wrapper {
	margin-left: 320px;
	padding-top: 60px;
	position: relative;
	transition: all 0.4s ease;
}
.page-wrapper > .content {
	padding: 1.875rem 1.875rem 0;
}
.page-header {
	margin-bottom: 1.875rem;
}
.page-header .breadcrumb {
    background-color: transparent;
    color: #6c757d;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0;
    padding: 0;
}
.page-header .breadcrumb a {
	color: #333;
}
.page-title {
	font-size: 28px;
    font-weight: 600;
    color: #333;
    margin-bottom: 5px;
}

/*-----------------
	12. Login
-----------------------*/

.login-body {
    display: table;
    height: 100vh;
    min-height: 100vh;
}
.login-wrapper {
	width: 100%;
	height: 100%;
	display: table-cell;
	vertical-align: middle;
}
.login-wrapper .loginbox {
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	display: flex;
    margin: 1.875rem auto;
    max-width: 800px;
    min-height: 500px;
    width: 100%;
}
.login-wrapper .loginbox .login-left {
    align-items: center;
    background: #18aefa;
    background-image: url(../img/login.jpg);
    border-radius: 6px 0 0 6px;
    flex-direction: column;
    justify-content: center;
    padding: 80px;
    width: 400px;
    display: flex;
    background-blend-mode: multiply;
}
.login-wrapper .loginbox .login-right {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 40px;
    width: 400px;
}
.login-wrapper .loginbox .login-right .login-right-wrap {
    max-width: 100%;
    flex: 0 0 100%;
}
.login-wrapper .loginbox .login-right h1 {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 5px;
    text-align: center;
}
.account-subtitle {
    color: #4c4c4c;
    font-size: 17px;
    margin-bottom: 1.875rem;
    text-align: center;
}
.login-wrapper .loginbox .login-right .forgotpass a {
	color: #a0a0a0;
}
.login-wrapper .loginbox .login-right .forgotpass a:hover {
	color: #333;
	text-decoration: underline;
}
.login-wrapper .loginbox .login-right .dont-have {
	color: #a0a0a0;
	margin-top: 1.875rem;
}
.login-wrapper .loginbox .login-right .dont-have a {
	color: #333;
}
.login-wrapper .loginbox .login-right .dont-have a:hover {
	text-decoration: underline;
}
.social-login {
	text-align: center;
}
.social-login > span {
	color: #a0a0a0;
	margin-right: 8px;
}
.social-login > a {
	background-color: #ccc;
	border-radius: 4px;
	color: #fff;
	display: inline-block;
	font-size: 18px;
	height: 32px;
	line-height: 32px;
	margin-right: 6px;
	text-align: center;
	width: 32px;
}
.social-login > a:last-child {
	margin-right: 0;
}
.social-login > a.facebook {
	background-color: #4b75bd;
}
.social-login > a.google {
	background-color: #fe5240;
}
.login-or {
	color: #a0a0a0;
	margin-bottom: 20px;
	margin-top: 20px;
	padding-bottom: 10px;
	padding-top: 10px;
	position: relative;
}
.or-line {
	background-color: #e5e5e5;
	height: 1px;
	margin-bottom: 0;
	margin-top: 0;
	display: block;
}
.span-or {
	background-color: #fff;
	display: block;
	left: 50%;
	margin-left: -20px;
	position: absolute;
	text-align: center;
	text-transform: uppercase;
	top: 0;
	width: 42px;
}
a.btn.btn-primary.btn-block {
    width: 100%;
}

/*-----------------
	13. Notifications
-----------------------*/

.notifications {
	padding: 0;
}
.notifications .notification-time {
	font-size: 12px;
	line-height: 1.35;
	color: #bdbdbd;
}
.notifications .media {
	margin-top: 0;
	border-bottom: 1px solid #f5f5f5;
}
.notifications .media:last-child {
	border-bottom: none;
}
.notifications .media a {
	display: block;
	padding: 10px 15px;
	border-radius: 2px;
}
.notifications .media a:hover {
	background-color: #fafafa;
}
.notifications .media > .avatar {
	margin-right: 10px;
}
.notifications .media-list .media-left {
	padding-right: 8px;
}
.topnav-dropdown-header {
	border-bottom: 1px solid #eee;
	text-align: center;
}
.topnav-dropdown-header, 
.topnav-dropdown-footer {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
}
.topnav-dropdown-footer {
	border-top: 1px solid #eee;
}
.topnav-dropdown-footer a {
	display: block;
	text-align: center;
	color: #333;
}
.user-menu.nav > li > a .badge {
	background-color: #FFBC53;
    display: block;
    font-size: 10px;
    font-weight: bold;
    height: 15px;
    width: 15px;
    position: absolute;
    right: 7px;
    top: 13px;
    color: #fff;
    padding: 3px;
}
.user-menu.nav > li > a > i {
	font-size: 1.5rem;
	line-height: 60px;
}
.noti-details {
	color: #989c9e;
    margin-bottom: 0;
}
.noti-title {
	color: #333;
}
.notifications .noti-content {
	height: 290px;
	width: 350px;
	overflow-y: auto;
	position: relative;
}
.notification-list {
	list-style: none;
	padding: 0;
	margin: 0;
}
.notifications ul.notification-list > li{
	margin-top: 0;
	border-bottom: 1px solid #f5f5f5;
}
.notifications ul.notification-list > li:last-child {
	border-bottom: none;
}
.notifications ul.notification-list > li a {
	display: block;
	padding: 10px 15px;
	border-radius: 2px;
}
.notifications ul.notification-list > li a:hover {
	background-color: #fafafa;
}
.notifications ul.notification-list > li .list-item {
    border: 0;
    padding: 0;
    position: relative;
}
.topnav-dropdown-header .notification-title {
    color: #333;
    display: block;
    float: left;
    font-size: 14px;
}
.topnav-dropdown-header .clear-noti {
    color: #18aefa;
    float: right;
    font-size: 12px;
    text-transform: uppercase;
}
.noti-time {
    margin: 0;
}

/*-----------------
	14. Dashboard
-----------------------*/
/*-----------------
	14. Dashboard
-----------------------*/

.bg-one {
	background-color: rgb(253 187 56 / 0.5);
	border-color: #fdbb38;
	border-radius: 30px 0px;
}
.bg-two {
	background-color: rgb(25 175 251 / 0.2);
	border-color: #19affb;
	border-radius: 30px 0px;
}
.bg-three {
	background-color: rgb(244 104 65 / 0.2);
	border-color: #f46841;
	border-radius: 30px 0px;
}
.bg-four {
	background-color: rgb(110 107 250 / 0.2);
	border-color: #6e6bfa;
	border-radius: 30px 0px;
}
.db-icon {
	font-size: 25px;
    width: 60px;
    height: 60px;
    padding: 13px 21px;
	border-radius: 10px;
	color: #fff;
	margin-right: 20px;
}
.bg-one .db-icon {
	background-color: #fdbb38;
}
.bg-two .db-icon {
	background-color: #19affb;
	padding: 13px 15px;
}
.bg-three .db-icon {
	background-color: #f46841;
}
.bg-four .db-icon {
	background-color: #6e6bfa;
}
.db-info h3 {
	font-size: 28px;
	font-weight: 600;
	color: #333333;
}
.db-info h6 {
    margin: 0px;
    font-weight: 400;
    font-size: 15px;
    color: #333333;
}
.card-chart .card-body {
	padding: 8px;
}
.activity-feed {
    list-style: none;
    margin-bottom: 0;
    margin-left: 5px;
    padding: 0;
}
.activity-feed .feed-item {
    border-left: 2px solid #e4e8eb;
    padding-bottom: 20px;
    padding-left: 20px;
    position: relative;
}
.activity-feed .feed-item:last-child {
	border-color: transparent;
	padding-bottom: 0;
}
.activity-feed .feed-item:after {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: -7px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: #18aefa;
}
.activity-feed .feed-item .feed-date {
	display: block;
	position: relative;
	color: #777;
	text-transform: uppercase;
	font-size: 13px;
	top: -2px;
 }
.activity-feed .feed-item .feed-text {
	color: #777;
	position: relative;
}
.activity-feed .feed-item .feed-text a {
    color: #333;
    font-weight: 600;
}
.activity-feed .feed-item .feed-date1 {
	display: block;
	position: relative;
	color: #777;
	font-size: 12px;
	top: -2px;
 }
 .activity-feed .feed-item .feed-text1 a {
    color: #333;
    font-weight: 600;
    font-size: 14px;
}
.activity-feed .feed-item p {
    color: #3bbafd;
    font-weight: 400;
    margin: 0;
    font-size: 14px;
}
.activity-feed .feed-item p span {
    color: #ffba38;
}
.sm-box {
    padding: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
.sm-box .svg-inline--fa {
    font-size: 80px;
    position: absolute;
    right: 15px;
    bottom: 0px;
}
.sm-box h6 {
    color: #fff;
    font-size: 25px;
    font-weight: 600;
}
.sm-box p {
    color: #fff;
    font-size: 25px;
    margin: 0px;
}
.fb {
    background-color: #3a5794;
    border: 1px solid #3a5794;
}
.twitter {
    background-color: #1d9bea;
    border: 1px solid #1d9bea;
}
.linkedin {
    background-color: #0e72a2;
    border: 1px solid #0e72a2;
}
.insta {
    background-color: #d34b7d;
    border: 1px solid #d34b7d;
}
.fa-facebook {
    color: #7085b0;
}
.fa-twitter {
    color: #5ebbf4;
    bottom: -10px !important;
    right: 18px !important;
}
.fa-instagram {
    color: #de7099;
    bottom: -7px !important;
    right: 20px !important;
}
.fa-linkedin-in {
    color: #4a92b5;
    bottom: -14px !important;
    right: 23px !important;
}


/*-----------------
	20. Teacher Dashboard
-----------------------*/

.bg-five {
	background-color: rgb(244 104 65 / 0.2);
    border-color: #f46841;
    border-radius: 30px 0px;
}
.bg-six {
    background-color: rgb(110 107 250 / 0.2);
    border-color: #6e6bfa;
    border-radius: 30px 0px;
}
.bg-seven {
    background-color: rgb(253 187 56 / 0.5);
    border-color: #fdbb38;
    border-radius: 30px 0px;
}
.bg-eight {
    background-color: rgb(25 175 251 / 0.2);
    border-color: #19affb;
    border-radius: 30px 0px;
}
.bg-nine {
	background-color: rgb(244 104 65 / 0.2);
    border-color: #f46841;
    border-radius: 30px 0px;
}
.bg-ten {
    background-color: rgb(253 187 56 / 0.5);
    border-color: #fdbb38;
    border-radius: 30px 0px;
}
.bg-eleven {
    background-color: rgb(25 175 251 / 0.2);
    border-color: #19affb;
    border-radius: 30px 0px;
}
.bg-five .db-icon {
	background-color: #f46841;
	padding: 13px 15px;
}
.bg-six .db-icon {
	background-color: #6e6bfa;
}
.bg-seven .db-icon {
	background-color: #fdbb38;
	padding: 13px 18px;
}
.bg-eight .db-icon {
	background-color: #19affb;
	padding: 13px 18px;
}
.bg-nine .db-icon {
	background-color: #f46841;
	padding: 13px 16px;
}
.bg-ten .db-icon {
	background-color: #fdbb38;
	padding: 13px 21px;
}
.bg-eleven .db-icon {
	background-color: #19affb;
	padding: 13px 21px;
}
.lesson {
	white-space: nowrap;
}
.lesson .table td {
    border-top: transparent;
    padding-right: 4px;
    padding-top: 0;
}
.lesson .table td + td {
	padding-top: .75rem;
}
.lesson .table td:first-child {
	padding-right: 0rem;
}
.lesson .table td:last-child {
	padding-left: 0rem;
	text-align: left;
}
.lesson .table tbody tr:last-child {
	border-bottom: transparent;
}
.lesson .date b {
	font-size: 13px;
	font-weight: 500;
	color: #3b3b3b;
}
.lesson .date p {
    font-size: 12px;
    font-weight: 400;
    color: #777777;
    margin-bottom: 0;
}
.lesson a {
	font-size: 12px;
	font-weight: 500;
	color: #3bbafd;
}
.lesson .btn {
	font-size: 14px;
	font-weight: 500;
}
.dash-circle {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.dash-widget {
	text-align: center;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
}
.circle-bar > div {
	display: inline-block;
	position: relative;
	text-align: center;
}
.circle-bar > div b {
	font-size: 24px;
	font-weight: 600;
	color: #b8b7ff;
    left: 0;
    position: absolute;
    top: 50%;
    right: 0;
    text-align: center;
    margin: 0 auto;
    transform: translateY(-50%);
}
.circle-bar > div canvas {
	width: 90px !important;
	height: 90px !important;
}
.dash-info {
	padding-top: 20px;
}
.dash-info h6 {
	font-size: 14px;
	font-weight: 500;
	color: #6e6bfa;
	margin-bottom: 10px;
}
.dash-info h4 {
	font-size: 26px;
	font-weight: 600;
	color: #6e6bfa;
	margin: 0;
}
.dash-info h4 span {
	color: #b8b7ff;
}
.calendar-info {
    border-top: 1px solid #eaeaea;
    padding-top: 10px;
    padding-bottom: 28px;
}
.calendar-info1 {
	padding-bottom: 0px;
}
.calendar-details {
	display: flex;
    justify-content: space-between;
    align-items: center;
	padding-top: 20px;
}
.calendar-info p {
	font-size: 12px;
	font-weight: 500;
	color: #777777;
	margin-bottom: 0;
}
.calendar-info h6 span {
	
}
.calendar-info .calendar-blue {
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    background: #d2efff;
    margin-bottom: 0;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}
.calendar-info .calendar-violet {
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    background: #e2e2fe;
    margin-bottom: 0;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}
.calendar-info .calendar-red {
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    background: #fde0d8;
    margin-bottom: 0;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}
.calendar-info .calendar-orange {
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    background: #ffefcf;
    margin-bottom: 0;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

/*-----------------
	20. Teacher Dashboard
-----------------------*/

.bg-five {
	background-color: rgb(244 104 65 / 0.2);
    border-color: #f46841;
    border-radius: 30px 0px;
}
.bg-six {
    background-color: rgb(110 107 250 / 0.2);
    border-color: #6e6bfa;
    border-radius: 30px 0px;
}
.bg-seven {
    background-color: rgb(253 187 56 / 0.5);
    border-color: #fdbb38;
    border-radius: 30px 0px;
}
.bg-eight {
    background-color: rgb(25 175 251 / 0.2);
    border-color: #19affb;
    border-radius: 30px 0px;
}
.bg-nine {
	background-color: rgb(244 104 65 / 0.2);
    border-color: #f46841;
    border-radius: 30px 0px;
}
.bg-ten {
    background-color: rgb(253 187 56 / 0.5);
    border-color: #fdbb38;
    border-radius: 30px 0px;
}
.bg-eleven {
    background-color: rgb(25 175 251 / 0.2);
    border-color: #19affb;
    border-radius: 30px 0px;
}
.bg-five .db-icon {
	background-color: #f46841;
	padding: 13px 15px;
}
.bg-six .db-icon {
	background-color: #6e6bfa;
}
.bg-seven .db-icon {
	background-color: #fdbb38;
	padding: 13px 18px;
}
.bg-eight .db-icon {
	background-color: #19affb;
	padding: 13px 18px;
}
.bg-nine .db-icon {
	background-color: #f46841;
	padding: 13px 16px;
}
.bg-ten .db-icon {
	background-color: #fdbb38;
	padding: 13px 21px;
}
.bg-eleven .db-icon {
	background-color: #19affb;
	padding: 13px 21px;
}
.lesson {
	white-space: nowrap;
}
.lesson .table td {
    border-top: transparent;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 0;
}
.lesson .table td + td {
	padding-top: .75rem;
}
.lesson .table td:first-child {
	padding-left: 1.5rem;
}
.lesson .table td:last-child {
	padding-right: 1.5rem;
	text-align: right;
}
.lesson .table tbody tr:last-child {
	border-bottom: transparent;
}
.lesson .date b {
	font-size: 13px;
	font-weight: 500;
	color: #3b3b3b;
}
.lesson .date p {
    font-size: 12px;
    font-weight: 400;
    color: #777777;
    margin-bottom: 0;
}
.lesson a {
	font-size: 12px;
	font-weight: 500;
	color: #3bbafd;
}
.lesson .btn {
	font-size: 14px;
	font-weight: 500;
}
.dash-circle {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.dash-widget3 {
	padding: 0 !important;
}
.dash-widget4 {
	padding: 0 !important;
}
.dash-widget {
	text-align: center;
	padding-top: 0.5rem;
	padding-bottom: 1.5rem;
}
.circle-bar > div {
	display: inline-block;
	position: relative;
	text-align: center;
}
.circle-bar > div b {
	font-size: 24px;
	font-weight: 600;
	color: #b8b7ff;
    left: 0;
    position: absolute;
    top: 50%;
    right: 0;
    text-align: center;
    margin: 0 auto;
    transform: translateY(-50%);
}
.circle-bar > div canvas {
	width: 100px !important;
	height: 100px !important;
}
.dash-info {
	padding-top: 20px;
}
.dash-info h6 {
	font-size: 14px;
	font-weight: 500;
	color: #6e6bfa;
	margin-bottom: 10px;
}
.dash-info h4 {
	font-size: 26px;
	font-weight: 600;
	color: #6e6bfa;
	margin: 0;
}
.dash-info h4 span {
	color: #b8b7ff;
}
.calendar-info {
    border-top: 1px solid #eaeaea;
}
.calendar-details {
	display: flex;
    justify-content: space-between;
    align-items: center;
	padding-top: 20px;
}
.calendar-info p {
	font-size: 12px;
	font-weight: 500;
	color: #777777;
	margin-bottom: 0;
}
.calendar-info h6 span {
	
}
.calendar-info .calendar-blue {
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    background: #d2efff;
    margin-bottom: 0;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}
.calendar-info .calendar-violet {
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    background: #e2e2fe;
    margin-bottom: 0;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}
.calendar-info .calendar-red {
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    background: #fde0d8;
    margin-bottom: 0;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}
.calendar-info .calendar-orange {
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    background: #ffefcf;
    margin-bottom: 0;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}


/*-----------------
	21. Student Dashboard
-----------------------*/
.dash-widget1 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-right: 1px solid #eaeaea;
}
.dash-widget2 {
	border: none;
}
.dash-widget1 .circle-bar {
    width: 230px;
    padding-right: 10px;
    text-align: center;
}
.dash-widget1 .circle-bar > div {
	display: inline-block;
	position: relative;
	text-align: center;
}
.dash-widget1 .circle-bar > div b {
	font-size: 24px;
	font-weight: 600;
	color: #b8b7ff;
    left: 0;
    position: absolute;
    top: 30%;
    right: 0;
    text-align: center;
    margin: 0 auto;
    transform: translateY(-50%);
}
.dash-widget1 .circle-bar > div canvas {
	width: 90px !important;
	height: 90px !important;
}
.dash-widget1 h6 {
	font-size: 14px;
	font-weight: 500;
	color: #6e6bfa;
	margin-top: 20px;
	margin-bottom: 10px;
	margin-left: 12px;
}
.dash-widget1 h4 {
	font-size: 20px;
	font-weight: 600;
	color: #6e6bfa;
	margin: 0;
}
.dash-widget1 h4 span {
	color: #b8b7ff;
}
.dash-details {
	padding-top: 0px;
}
.dash-details h4 {
	font-size: 16px;
	font-weight: 600;
	color: #3b3b3b;
	margin-bottom: 10px;
}
.dash-widget1 ul {
	padding: 0;
	margin: 0 -6px;
	list-style: none;
}
.dash-widget1 ul li {
	padding: 6px 6px;
	display: inline-block;
	font-size: 14px;
    font-weight: 500;
    color: #777;
}
.dash-widget1 .dash-btn {
	margin-top: 30px;
}
.dash-widget1 .dash-btn .btn {
    font-size: 14px;
    font-weight: 500;
    width: 110px;
    margin-bottom: 10px;
    margin-right: 10px;
    color: #ffffff;
    background: #009efb;
}
.dash-widget1 .dash-btn .btn:last-child {
	margin-right: 0;
}
.dash-widget1 .dash-btn .btn:hover {
	color: #009efb;
    background: transparent;
}
.dash-widget1 .dash-btn .btn-border {
    color: #009efb;
    background: transparent;
}
.dash-widget1 .dash-btn .btn-border:hover {
	color: #ffffff;
    background: #009efb;
}
.view-link {
	font-size: 14px;
}

.m-left {
	margin-left: -14px;
}
/*-----------------
	15. Student Details
-----------------------*/

.about-info img {
	width: 250px;
}
.about-info ul li {
	font-size: 18px;
    margin: 0 0 15px 10px;
    display: flex;
}
.title-span {
    font-weight: 500;
    width: 150px;
}
.blue-box {
    background-color: #18aefa;
    padding: 40px 30px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	color: #fff;
	text-align: center;
}
.follow-sec h3 {
    font-size: 35px;
    font-weight: 600;
}
.follow-sec p {
    font-size: 18px;
    margin: 0;
}
.skill-info {
	padding-top: 20px;
	border-top: 1px solid #ddd;
}
.skill-info ul li {
	margin-bottom: 25px;
}

/*-----------------
	16. Event
-----------------------*/

.calendar-events {
    border: 1px solid transparent;
    cursor: move;
    padding: 10px 15px;
}
.calendar-events:hover {
	border-color: #e9e9e9;
    background-color: #fff;
}
.calendar-events i {
    margin-right: 8px;
}
.calendar {
	float: left;
	margin-bottom: 0;
}
.fc-toolbar.fc-header-toolbar {
    margin-bottom: 1.5rem;
}
.none-border .modal-footer {
	border-top: none;
}
.fc-toolbar h2 {
	font-size: 18px;
	font-weight: 600;
	font-family: 'Roboto', sans-serif;
	line-height: 30px;
	text-transform: uppercase;
}
.fc-day-grid-event .fc-time {
	font-family: 'Roboto', sans-serif;
}
.fc-day {
	background: #fff;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
	z-index: 0;
}
.fc th.fc-widget-header {
	background: #eeeeee;
	font-size: 14px;
	line-height: 20px;
	padding: 10px 0;
	text-transform: uppercase;
}
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
	border-color: #f3f3f3;
}
.fc-basic-view .fc-day-number, 
.fc-basic-view .fc-week-number {
    padding: 2px 5px;
}
.fc-button {
	background: #f1f1f1;
	border: none;
	color: #797979;
	text-transform: capitalize;
	box-shadow: none !important;
	border-radius: 3px !important;
	margin: 0 3px !important;
	padding: 6px 12px !important;
	height: auto !important;
}
.fc-text-arrow {
	font-family: inherit;
	font-size: 16px;
}
.fc-state-hover {
	background: #f3f3f3;
}
.fc-state-highlight {
	background: #f0f0f0;
}
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
	background-color: #FFBC53 !important;
	color: #fff !important;
	text-shadow: none !important;
}
.fc-cell-overlay {
	background: #f0f0f0;
}
.fc-unthemed .fc-today {
	background: #fff;
}
.fc-event {
	border-radius: 2px;
	border: none;
	color: #fff !important;
	cursor: move;
	font-size: 13px;
	margin: 1px 7px;
	padding: 5px 5px;
	text-align: center;
}
.fc-basic-view td.fc-week-number span {
	padding-right: 8px;
	font-weight: 700;
	font-family: 'Roboto', sans-serif;
}
.fc-basic-view td.fc-day-number {
	padding-right: 8px;
	font-weight: 700;
	font-family: 'Roboto', sans-serif;
}
.event-form .input-group .form-control {
	height: 40px;
}
.submit-section {
	text-align: center;
	margin-top: 40px;
}
.submit-btn {
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    min-width: 200px;
    padding: 10px 20px;
}

/*-----------------
	17. Inbox
-----------------------*/


.dropdown-action {
	margin-bottom: 5px;
	
}
.dropdown-action .dropdown-toggle:after {
	display: none;
}
.table-inbox input[type="radio"],
.table-inbox input[type="checkbox"] {
	cursor: pointer;
}
.mail-list {
	list-style: none;
	padding: 0;
}
.mail-list > li > a {
	color: #333;
	display: block;
	padding: 10px;
}
.mail-list > li.active > a {
	color: #FFBC53;
	font-weight: bold;
}
.email-header .btn-primary {
    background-color: #fff;
    border: 1px solid #ccc;
    color: #333;
}
.unread .name,
.unread .subject,
.unread .mail-date {
	color: #000;
	font-weight: 600;
}
.table-inbox .starred.fa-star {
	color: #ffd200;
}
.table.table-inbox > tbody > tr > td,
.table.table-inbox > tbody > tr > th,
.table.table-inbox > tfoot > tr > td,
.table.table-inbox > tfoot > tr > th,
.table.table-inbox > thead > tr > td,
.table.table-inbox > thead > tr > th {
    border-bottom: 1px solid #f2f2f2;
    border-top: 0;
}
.table-inbox {
	font-size: 15px;
	margin-bottom: 0;
}
.table.table-inbox thead {
	background-color: #fff;
}
.note-editor.note-frame {
	border: 1px solid #ddd;
	box-shadow: inherit;
}
.note-editor.note-frame .note-statusbar {
	background-color: #fff;
}
.note-editor.note-frame.fullscreen {
	top: 60px;
}
.note-editor.note-frame .btn-light {
    background-color: #f9f9f9;
    box-shadow: unset;
    color: #333;
}
.mail-title {
	font-weight: bold;
	text-transform: uppercase;
}
.form-control.search-message {
    border-color: #ccc;
    border-radius: 4px;
    height: 38px;
    width: 180px;
}
.table-inbox tr {
	cursor: pointer;
}
table.table-inbox tbody tr.checked {
	background-color: #ffffcc;
}
.mail-label {
    font-size: 16px !important;
    margin-right: 5px;
}
.inbox-menu {
	display: inline-block;
	margin: 0 0 1.875rem;
	padding: 0;
	width: 100%;
}
.inbox-menu li {
	display: inline-block;
	width: 100%;
}
.inbox-menu li + li {
	margin-top: 2px;
}
.inbox-menu li a {
	color: #333;
	display: inline-block;
	padding: 10px 15px;
	width: 100%;
	text-transform: capitalize;
	-webkit-transition: 0.3s ease;
	-moz-transition: 0.3s ease;
	transition: 0.3s ease;
}
.inbox-menu li a i {
	font-size: 16px;
	padding-right: 10px;
	color: #878787;
}
.inbox-menu li a:hover, .inbox-menu li.active a, .inbox-menu li a:focus {
	background: rgba(33, 33, 33, 0.05);
}
.compose-btn {
	margin-bottom: 1.875rem;
}
.compose-btn a {
    font-weight: 600;
    padding: 8px 15px;
}
a.btn .btn-block {
    width: 100%;
}

/*-----------------
	18. Error
-----------------------*/

.error-page {
    align-items: center;
    color: #1f1f1f;
    display: flex;
}
.error-page .main-wrapper {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    justify-content: center;
    width: 100%;
	min-height: unset;
}
.error-box {
    margin: 0 auto;
    max-width: 480px;
    padding: 1.875rem 0;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.error-box h1 {
    color: #FFBC53;
    font-size: 10em;
}
.error-box p {
    margin-bottom: 1.875rem;
}
.error-box .btn {
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    min-width: 200px;
    padding: 10px 20px;
}

/*-----------------
	19. Profile
-----------------------*/

.profile-header {
    background: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    border: 1px solid #efefef;
    padding: 1.5rem;
}
.profile-menu {
    background-color: #fff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    padding: 0.9375rem 1.5rem;
}
.profile-menu .nav-tabs.nav-tabs-solid {
	background-color: transparent;
}
.profile-header img {
    height: auto;
    max-width: 120px;
    width: 120px;
}
.profile-tab-cont {
	padding-top: 1.875rem;
}
.profile-menu.nav.nav-tabs a {
	color: #000;
}
.profile-menu.nav.nav-tabs a.active {
	color: #fff;
	background-color: #fcba38;
}
.about-text {
    max-width: 500px;
}
.skill-tags span {
    background-color: #f4f4f5;
    border-radius: 4px;
    color: #66676b;
    display: inline-block;
    font-size: 15px;
    line-height: 22px;
    margin: 2px 2px;
    padding: 5px 15px;
}
.edit-link {
    color: #66676b;
    font-size: 16px;
    margin-top: 4px;
}
.form-title {
    width: 100%;
    max-width: 100%;
    padding: 0;
    font-size: 1.25rem;
	font-weight: 500;
    line-height: inherit;
    color: #333;
    white-space: normal;
    position: relative;
    display: block;
    margin-bottom: 20px;
}
.form-title:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    top: 50%;
    background-color: rgba(0, 0, 0, 0.1);
}
.form-title span {
    padding: 0 0.5rem 0 0;
    background-color: #fff;
    display: inline-block;
    z-index: 2;
    position: relative;
}

/*-----------------
	20. Responsive
-----------------------*/


@media only screen and (min-width: 1199.5px) and (max-width: 1620px) {
	.sm-box p {
		color: #fff;
		font-size: 18px;
	}
	.fa-twitter {
		bottom: -6px !important;
	}
	.fa-instagram {
		bottom: -4px !important;
	}
	.fa-linkedin-in {
		bottom: -9px !important;
	}
}

@media (min-width:768px) {
	.avatar-xxl {
		width: 8rem;
		height: 8rem;
	}
	.avatar-xxl .border {
		border-width: 4px !important;
	}
	.avatar-xxl .rounded {
		border-radius: 12px !important;
	}
	.avatar-xxl .avatar-title {
		font-size: 42px;
	}
	.avatar-xxl.avatar-away:before,
	.avatar-xxl.avatar-offline:before,
	.avatar-xxl.avatar-online:before {
		border-width: 4px;
	}
}

@media (min-width: 992px) {
	#toggle_btn {
		align-items: center;
		color: #18aefa;
		display: inline-flex;
		float: left;
		font-size: 26px;
		height: 60px;
		justify-content: center;
		margin-left: 15px;
		padding: 0 15px;
	}
	.mini-sidebar .header-left .logo img {
		height: auto;
		max-height: 40px;
		width: auto;
	}
	.mini-sidebar .header .header-left .logo {
		display: none;
	}
	.mini-sidebar .header-left .logo.logo-small {
		display: block;
	}
	.mini-sidebar .header .header-left {
		padding: 0 5px;
		width: 78px;
	}
	.mini-sidebar .sidebar {
		width: 78px;
	}
	.mini-sidebar.expand-menu .sidebar {
		width: 240px;
	}
	.mini-sidebar .menu-title {
		visibility: hidden;
		white-space: nowrap;
	}
	.mini-sidebar.expand-menu .menu-title {
		visibility: visible;
	}
	.mini-sidebar .menu-title a {
		visibility: hidden;
	}
	.mini-sidebar.expand-menu .menu-title a {
		visibility: visible;
	}
	.modal-open.mini-sidebar .sidebar {
		z-index: 1051;
	}
	.mini-sidebar .sidebar .sidebar-menu ul > li > a span {
		display: none;
		transition: all 0.2s ease-in-out;
		opacity: 0;
	}
	.mini-sidebar.expand-menu .sidebar .sidebar-menu ul > li > a span {
		display: inline;
		opacity: 1;
	}
	.mini-sidebar.expand-menu .sidebar .sidebar-menu > ul > li > a i {
		width: 20px;
	}
	.mini-sidebar .page-wrapper {
		margin-left: 78px;
	}
}

@media (max-width: 1199.5px) {
	.sm-box h6 {
		font-size: 30px;
	}
	.sm-box p {
		font-size: 22px;
	}
	.sm-box .fab {
		font-size: 100px;
	}
	.dash-widget3 {
		padding-right: 15px;
	}
	.dash-widget4 {
		padding-left: 15px;
	}
}

@media (max-width: 991.98px) {
	.header .header-left {
		position: absolute;
		width: 100%;
		padding: 0px 50px;
	}
	.mobile_btn {
		color: #18aefa !important;
		cursor: pointer;
		display: block;
		font-size: 24px;
		height: 60px;
		left: 0;
		line-height: 60px;
		padding: 0 15px;
		position: absolute;
		text-align: center;
		top: 0;
		z-index: 10;
	}
	#toggle_btn {
		display: none;
	}
	.top-nav-search {
		display: none;
	}
	.login-wrapper .loginbox .login-left {
		padding: 80px 50px;
		width: 50%;
	}
	.login-wrapper .loginbox .login-right {
		padding: 50px;
		width: 50%;
	}
	.sidebar {
		margin-left: -225px;
		width: 225px;
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
		z-index: 1041;
	}
	.page-wrapper {
		margin-left: 0;
		padding-left: 0;
		padding-right: 0;
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
	}
	.user-menu.nav > li > a {
		color: #18aefa;
	}
	.user-menu.nav > li > a .badge {
		background-color: #FFBC53;
		color: #fff;
	}
	.user-menu.nav > li > a:hover i, .user-menu.nav > li > a:focus i {
		color: #fff;
	}
	.sm-box h6 {
		font-size: 25px;
	}
	.sm-box p {
		font-size: 20px;
	}
	.sm-box .fab {
		font-size: 100px;
	}
	footer { 
		text-align: center;
	}
	.dash-circle {
		display: block;
	}
	.dash-circle .col-12 {
		display: flex;
	}
	.dash-widget1 {
		border-bottom: 1px solid #eaeaea;
		border-right: none;
	}
	.dash-widget1 ul li {
		width: 40%;
	}
	.page-header .breadcrumb {
		font-size: 15px;
	}
}

@media (max-width: 767.98px) {
	body {
		font-size: 0.9375rem;
	}
	h1, .h1 {
		font-size: 2rem;
	}
	h2, .h2 {
		font-size: 1.75rem;
	}
	h3, .h3 {
		font-size: 1.5rem;
	}
	h4, .h4 {
		font-size: 1.125rem;
	}
	h5, .h5 {
		font-size: 1rem;
	}
	h6, .h6 {
		font-size: 0.875rem;
	}
	.header .has-arrow .dropdown-toggle:after {
		display: none;
	}
	.user-menu.nav > li > a > span:not(.user-img) {
		display: none;
	}
	.navbar-nav .open .dropdown-menu {
		float: left;
		position: absolute;
	}
	.navbar-nav.user-menu .open .dropdown-menu {
		left: auto;
		right: 0;
	}
	.header .header-left {
		padding: 0 15px;
	}
	.header .header-left .logo {
		display: none;
	}
	.header-left .logo.logo-small {
		display: inline-block;
		left: 50%;
		position: relative;
		transform: translateX(-50%);
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
	}
	.login-wrapper .loginbox .login-left {
		display: none;
	}
	.login-wrapper .loginbox {
		max-width: 450px;
		min-height: unset;
	}
	.login-wrapper .loginbox .login-right {
		float: none;
		padding: 1.875rem;
		width: 100%;
	}
	.left-action {
		text-align: center;
		margin-bottom: 15px;
	}
	.right-action {
		text-align: center;
	}
	.top-action-left .float-left {
		float: none !important;
	}
	.top-action-left .btn-group {
		margin-bottom: 15px;
	}
	.top-action-right {
		text-align: center;
	}
	.top-action-right a.btn.btn-white {
		margin-bottom: 15px;
	}
	.mail-sent-time {
		float: left;
		margin-top: 10px;
		width: 100%;
	}
	.profile-btn {
		flex: 0 0 100%;
		margin-top: 20px;
	}
	.edit-link {
		font-size: 0.875rem;
		margin-top: 0;
	}
	.product_price {
		font-size: 1.5rem;
	}
	.login-wrapper .loginbox .login-right h1 {
		font-size: 22px;
	}
	.error-box h1 {
		font-size: 6em;
	}
	.error-box .btn {
		font-size: 15px;
		min-width: 150px;
		padding: 8px 20px;
	}
	.user-menu {
		padding-right: 0px;
	}
	.page-title {
		font-size: 22px;
	}
	.db-info h3 {
		font-size: 22px;
	}
}

@media (max-width: 575.98px) {
	.card {
		margin-bottom: 0.9375rem;
	}
	.page-wrapper > .content {
		padding: 0.9375rem 0.9375rem 0;
	}
	.card-body {
		padding: 1.25rem;
	}
	.card-header {
		padding: .75rem 1.25rem;
	}
	.card-footer {
		padding: .75rem 1.25rem;
	}
	.card-chart .card-body {
		padding: 5px;
	}
	.page-header {
		margin-bottom: 0.9375rem;
	}
	.account-wrapper {
		padding: 0.9375rem;
	}
	.profile-image {
		flex: 0 0 100%;
		margin-bottom: 20px;
		text-align: center;
	}
	.profile-user-info {
		text-align: center;
	}
	.profile-btn {
		text-align: center;
	}
	.fc-toolbar .fc-right {
		display: inline-block;
		float: none;
		margin: 10px auto 0;
		width: auto;
		clear: both;
	}
	.fc-toolbar .fc-left {
		float: none;
		margin: 0 auto;
		width: 200px;
	}
	.fc-toolbar .fc-center {
		display: inline-block;
		width: 100%;
		text-align: center;
	}
	.fc-toolbar .fc-center h2 {
		width: 100%;
	}
	.profile-tab-cont {
		padding-top: 1.25rem;
	}
	.about-info .media {
		flex-direction: column;
	}
	.title-span {
		width: 100px;
	}
	.about-info ul li {
		font-size: 13px;
	}
	.dash-widget1 {
	    display: block;
	}
	.dash-widget2 {
		border: none;
	}
	.dash-widget1 .circle-bar {
	    width: auto;
	    padding-right: 0px;
	    text-align: center;
	}
	.dash-details {
	    padding-top: 20px;
	    text-align: center;
	}
	.dash-widget1 ul {
		margin: 0 0px;
	}
	.dash-widget1 ul li {
	    width: auto;
	    display: inline-block;
	}
	.view-link {
	    font-size: 13px;
	}
}

@media (max-width: 479px) {
	/* .page-header .breadcrumb {
		display: none;
	} */
	.dropdown-menu.notifications.show {
		transform: translate3d(-190px, 60px, 0px) !important;
	}
	.user-menu .dropdown-menu {
		width: 310px;
	}
	.notifications .noti-content {
		width: 310px;
		height: 355px;
	}
}
/*-----------------
	21. Blog
-----------------------*/

.blog {
	background: #fff;
	border-radius: 18px;
    margin-bottom: 25px;
    padding: 15px;
    position: relative;
}
.grid-blog .blog-image {
    margin-bottom: 20px;
    border-radius: 10px;
}
.blog-image, .blog-image > a, .blog-image img {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
}
.blog-image img {
	border-radius: 10px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    -moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    -ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    -o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    -webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.blog-content {
    position: relative;
	margin-bottom: 10px;
}
.grid-blog .entry-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 0;
	padding: 0;
}
.table tbody tr:last-child {
    border-color: transparent;
}
.categories-table tr:last-child td {
    padding-bottom: 0px;
}
.grid-blog .entry-meta li {
    margin-bottom: 15px;
}
.entry-meta li {
    display: inline-block;
    margin-right: 15px;
}
.grid-blog .post-author {
    width: 189px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.back-btn {
	font-weight: 600;
	font-size: 16px;
	color: #3d5ee1;
	display: inline-block;
	margin-bottom: 18px;
}
.post-author span:nth-child(1):hover {
    color: #3d5ee1;
}
.post-author a {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
}
.post-author img {
    border-radius: 5px;
    width: 42px;
    height: 42px;
    margin-right: 10px;
}
.grid-blog .blog-title {
    font-size: 16px;
    margin: 0 0 6px;
}
.grid-blog .blog-title a {
	color: #333333;
}
.grid-blog .blog-content p {
    font-size: 12px;
    margin: 0 0 15px;
    color: #71717A;
}
.post-title {
	display: block;
	color: #131523;
    font-weight: 600;
}
.post-date {
	font-size: 12px;
	color: #7E84A3;
}
.blog-views {
	position: absolute;
	top: 20px;
	left: 15px;
	background: #F5F6FA;
    border-radius: 6px;
    padding: 2px 5px;
	display: flex;
    display: -ms-flexbox;
    align-items: center;
    font-size: 12px;
}
.blog-catagories {
	position: absolute;
	top: 15px;
	right: 15px;
	background: #3d5ee1;
    border-radius: 6px;
    min-width: 92px;
    padding: 6px 10px;
	display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center
}
.blog-catagories p{
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0px;
}
.blog-views img {
	width: auto;
	margin-right: 5px;
}
.post-list .post-author a span:hover {
    color: #3d5ee1;
}
.edit-delete-btn a{
    margin-right: 6px;
    font-size: 12px;
    border-radius: 5px;
    padding: 6px 13px;
}
.edit-delete-btn a:nth-child(1){
    margin-right: 6px;
    background: rgba(23, 208, 83, 0.21);
}
.edit-delete-btn a:nth-child(2){
    background: rgba(255, 0, 0, 0.1);
}
.btn-blog {
	padding: 10px 22px;
}
.edit-options{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.active-text {
	color: #1B5A90;
}
.modal.contentmodal .modal-content .submit-section .btn.btn-download {
	min-width: 200px;
}
.list-links {
	display: flex;
    display: -ms-flexbox;
	flex-wrap: wrap;
	padding: 0;	
}
.list-links li {
	margin: 0 15px 10px 0;
    list-style: none;
}
.list-links li a {
	background: #f2f0f0;
	border-radius: 5px;
	padding: 12px 30px;
	font-size: 14px;
	color: #131523;
	border: 1px solid #F5F6FA;
	display: inline-block;
}
.inactive-style {
    font-size: 14px;
}
.list-links li a:hover {
	background: #e5e5e8;
}
.list-links li a span {
	font-size: 14px;
}
.list-links li.active a {
	background: #FFFFFF;
    border: 1px solid #3d5ee1;
	color: #3d5ee1;
}
.user-menu.nav > li.view-btn > a {
    color: #fff;
    padding: 10px 15px;
    border-radius: 6px;
    background-color: #3d5ee1;
}
.user-menu.nav > li.view-btn > a:hover {
    background-color: #18aefa;
    color: #fff;
}
.user-menu.nav > li.view-btn > a:hover i {
    color: #fff;
}
.user-menu.nav > li.view-btn > a i {
    font-size: 17px;
    position: relative;
}
.view-btn {
    margin-right: 15px;
}
.select-by {
    min-width: 134px;
    margin-right: 25px;
}
.select-by .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #131523;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding:4px;
    height:0;
    left:50%;
    margin-left:-5px;
    margin-top:-5px;
    position: absolute;
    top: 45%;
    width: 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
.modal-select-box .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #131523;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding:4px;
    height:0;
    left:50%;
    margin-left:-5px;
    margin-top:-5px;
    position: absolute;
    top: 45%;
    width: 0;
    transform: rotate(224deg);
    -webkit-transform: rotate(224deg);
}
.select-by .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: #131523;
    border-width: 2px 0 0 2px;
    padding:3px;
    margin-top:-2px;
}
.pagination-tab{
    padding: 30px;
}
.header.fixed-header .user-menu.nav > li.view-btn > a{
    background-color: #fff;
    color: #000000;
}
.header.fixed-header .user-menu.nav > li.view-btn > a:hover{
    background-color: #d19eb7;
    color: #fff;
}
.modal-select-box .select2-container--default .select2-selection--single .select2-selection__arrow b  {
    border-color: #131523;
    border-width: 2px 0 0 2px;
    padding:3px;
    margin-top:-2px;
}
.status-toggle {
    display: flex;
    align-items: center;
}
.status-toggle span{
    margin-left: 8px;
    font-weight: 400;
    font-size: 14px;
}
.del-icon {
	margin-bottom: 23px;
}
.del-icon i {
	font-size: 60px;
	color: #F0142F;
	margin-bottom: 23px;
}
.modal-content .close-btn i {
    font-size: 20px;
    color: #F0142F;
}
.modal.contentmodal .modal-content .submit-section .btn {
    padding: 7px 12px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    min-width: 150px;
    margin-top: 5px;
}
.modal-content.doctor-profile{
	border-radius: 10px;
}
.change-photo-btn {
    color: #7E84A3;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    font-size: 13px;
    font-weight: 600;
    padding: 15px;
    position: relative;
    transition: .3s;
    text-align: center;
    height: 120px;
    background: #FAFAFA;
    border: 1px dashed #E1E1E1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    margin: auto;
    margin-bottom: 10px;
}
.change-photo-btn input.upload {
    bottom: 0;
    cursor: pointer;
    filter: alpha(opacity=0);
    left: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    height: 100px;
    width: 100%;
}
.bootstrap-tagsinput {
    display: block;
    border-radius: 5px;
    background: #FFf;
    border: 1px solid #ddd;
    min-height: 40px;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 10px;
    width: 100%;
    box-shadow: none;
}
.bootstrap-tagsinput input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0 6px;
    margin: 0;
    width: auto;
    max-width: inherit;
}
.bootstrap-tagsinput .tag {
    margin-right: 5px;
    margin-bottom: 5px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 7px 30px 7px 15px;
    border-radius: 40px;
}

/*-----------------
	22. Blog Details
-----------------------*/
.close-btn{
    border: 0;
    outline: 0;
    background: transparent;
    font-size: 18px;
}
.blog-single-post .blog-image {
	margin-bottom: 20px;
	border-radius: 25px;
}
.blog-single-post .blog-title {
	font-weight: 700;
	color: #000000;
	margin-bottom: 25px;
    font-size: 20px;
}
.about-author-img {
	background-color: #fff;
	height: 62px;
	width: 62px;
}
.about-author-img img {
	border-radius: 5px;
	height: 62px;
	width: 62px;
}
.author-details {
	margin-left: 20px;
	flex: 1;
}
.about-author {
	display: flex;
    display: -ms-flexbox;
	align-items: center;
}
.author-details .blog-author-name {
	display: inline-block;
	color: #131523;
	font-weight: 600;
	margin-bottom: 5px;
}
.author-details .blog-author-name span {
	font-size: 12px;
	font-weight: 400;
	color: #7E84A3;
	padding-left: 3px;
}
.author-details p {
	font-size: 12px;
}
.blog-comments .comments-list {
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}
.blog-comments .comments-list li {
	clear: both;
	padding-left: 57px;
}
.blog-comments .comments-list li .comment {
	margin-bottom: 20px;
}
.blog-comments .comments-list li .comment-author {
	left: 0;
	position: absolute;
}
.blog-comments .comments-list li img.avatar {
	height: 42px;
	width: 42px;
	border-radius: 5px;
}
.blog-comments .comment-btn {
    color: #000000;
    display: inline-block;
    font-size: 12px;
}
.blog-comments .comment-btn img {
	width: auto !important; 
}
.blog-author-name {
	font-size: 14px;
    font-weight: 600;
	margin-bottom: 5px;
}
.blog-date {
    color: #7E84A3;
    font-size: 12px;
	padding-left: 15px;
    font-weight: 400;
}
.comments-list .comment-block p {
    font-size: 12px;
    margin-bottom: 10px;
}
.new-comment .form-floating>.form-control, .form-floating>.form-select {
    height: 50px;
}
.new-comment .form-floating>label {
    padding: 0.8rem 0.6rem;
}
.social-share {
	float:left;
	list-style: none;
	margin: 0;
	padding: 0;
}
.social-share > li {
	display: inline-block;
	float:left;
	margin-left: 10px;
	text-align: center;
}
.social-share > li:first-child {
	margin-left: 0;
}
.social-share > li > a {
	border: 1px solid #3d5ee1;
	border-radius: 6px;
	color: #3d5ee1;
	display: flex;
    display: -ms-flexbox;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	height: 40px;
	width: 40px;
}
.social-share > li > a:hover {
	background-color: #3d5ee1;
	color: #fff;
	border-color: #fff;
}
.post-list ul {
	margin: 0 0 20px;
	padding-left: 0;
	list-style: none;
	display: flex;
    display: -ms-flexbox;
	align-items: center;
	flex-wrap: wrap;
}
.post-list ul li {
	font-size: 12px;
	color: #7E84A3;
	margin-bottom: 5px;
	margin-right: 30px;
}
.post-list ul li i {
	padding-right: 5px;
}
.post-list ul li:last-child {
	margin-right: 0;
}
.post-author img {
    border-radius: 5px;
    width: 36px;
	height: 36px;
    margin-right: 10px;
}
.post-author span:nth-child(1) {
	font-size: 14px;
    font-weight:500;
	color:  #131523;;
    transition: all 0.5s;
}
.post-author span:nth-child(2) {
	font-size: 12px;
    font-weight:400;
	color:  #7E84A3;;
    transition: all 0.5s;
}
.blog-content p {
    font-size: 14px;
}
a.blog-author-name:hover {
    color: #3d5ee1;
}
a.comment-btn:hover {
    color: #3d5ee1;
}
.save-btn {
	font-weight: 600;
    font-size: 16px;
    padding: 6px 75px;
}
.upload-file {
	margin-bottom: 20px;
}
.upload-file h6 {
	font-weight: 400;
	margin-bottom: 15px;
}
.upload-file img {
	border-radius: 5px;
	margin-bottom: 6px;
}
.upload-file p {
	font-size: 12px;
	color: #7E84A3;
}
.up-img {
	position: relative;
	padding-left: 11px;
}
.up-img .close-icon {
	position: absolute;
    top: -9px;
}
.table-action-btn {
    width: 79px;
    height: 27px;
}
.bank-details .modal-footer.blog-categories-btn {
    justify-content: start;
}
.blog-categories-btn {
   padding: 20px;
}
.modal-footer.blog-categories-btn >.bank-details-btn .btn {
    border-radius: 4px;
}
.checktoggle.checkbox-bg {
    background-color: #fff;
    border: 1px solid #D7D7D7;
}
.checktoggle.checkbox-bg::after {
    background: rgba(0, 0, 0, 0.25);
}
.check:checked + .checktoggle.checkbox-bg::after {
    background-color: #fff;
}
.categories-table .pagination {
    display: none;
}
.categories-table .dataTables_info {
    display: none;
}
.categories-table tr:last-child td {
    padding-bottom: 0px;
}
.blog-view .card-header{
    padding: 15px ;
}
.blog-image:hover img {
    transform: scale(1.1);
}
.blog-image {
	overflow: hidden;
}
.grid-structure .grid-container {
    background-color: #dee2e6;
    margin-bottom: 10px;
    padding: 10px 20px;
}


/*-----------------
	1. Custom
-----------------------*/
.center {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
  }