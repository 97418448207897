:root {
  --first-col-width: 13vw;
  --active-sidebar-icon-color: #8d286f;
  --active-sidebar-background-color: #5cf9f5;
  --small-screen-size: 992px;
  /* --primary-color: #1c2032; */
  --primary-color: #242940;
  --primary-color-light: #414c77;
  --primary-color-lighter: #99a1c6;
  --primary-color-lighter-rgb: 153, 161, 198;
  --primary-color-dark: #1c2032;
  --secondary-color: #82db62;
  --progress-bar-background: #d3d3d3;
  --error-color: #ff3333;
  --container-background-color: #ffffff;
}
body {
  background-color: #f1f1f1;
}

/*-----------------
	Default Style 
-----------------------*/
.c-button {
  display: inline-block;
  font-weight: 400;
  color: #FFF;
  text-align: center;
  /* vertical-align: middle; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* background-color: #6951dd; */
  background-color: var(--primary-color);
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.c-button:hover:enabled {
  background-color: var(--primary-color-light);
  text-decoration: none;
}

.c-button:focus:enabled {
 /* background-color: #503db3;
 outline: 1px solid #3f3185; */
  background-color: var(--primary-color-dark);
  outline: 1px solid black;
}

.c-button:disabled {
  background-color: #3a3a3a;
  color: #cfcfcf;
}

.c-label {
  display: block;
  font-size: 0.95rem;
  margin-bottom: -1px;
  float: left;
  font-weight: 300;
}

input:focus ~ .c-label, select:focus ~ .c-label, textarea:focus ~ .c-label {
  font-weight: 500;
}

.c-input-container {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
}

.c-page-container {
  background: var(--container-background-color);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 20px;
}

.c-panel-container {

  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 15px;
}

.error-msg {
  color: var(--error-color);
}

.success-msg {
  color: #4BB543;

}

.error-msg, .success-msg {
  font-weight: 500;
  font-size: 0.9rem;
  padding: 0px 10px;
  text-align: center;
}

.c-input-text-field {
  border-radius: 4px;
  border: 1px solid #ced4da;
  padding: 3.5px 12px 3.5px 12px;
  box-sizing: border-box;
  transition: border 0s ease-in;
}

.c-input-text-field:focus {
  outline: 1.5px solid var(--primary-color);
  transition: all 0s ease-in;
}

.c-vertical-container-spacing {
  padding: 20px 0px 20px 0px;
}



.main-page-content {
  /* height: 10vw;
  width: 10vw; */
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: var(--first-col-width) 25vw 25vw auto;
  transition: grid-template-columns 0.25s ease-in-out;
}

input[type=checkbox] {
  width: 1.1rem;
  height: 1.1rem;
  /* outline: 3px solid var(--primary-color-dark) !important;
  -webkit-appearance: none;
  appearance: none;
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 10%; */
  
}

input[type=checkbox]:checked {
  /* background-color: var(--primary-color-light)!important;
  outline: 3px solid var(--primary-color-dark) !important;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  border-radius: 50%;
  color: #ffffff;

  border: var(--primary-color-lighter) solid 3px; */
}


.disabled-link
{
  font-weight: 300;
}

/*-----------------
	Navigation Sidebar Style 
-----------------------*/

.sidenav {
    /* height: 100vw; 
    width: 250px; */
    background-color: var(--primary-color);
    /* background-image: linear-gradient(var(--primary-color), #5694c3); */
    /* z-index: 1; */
    /* /* top: 0;
    left: 0; */
    /* overflow: auto; */
  position: fixed;
  padding-top: 10px;
  grid-row: 1 / 4;
  grid-column: 1 / 2;
  height: 100%;
  width: var(--first-col-width);
  /* min-width: 150px; */
  color: white;
  transition: all 0.25s ease-in-out;
  }

.sidenav-header {
  margin-left: 84%;
  font-size: 22px;
  margin-bottom: 1vw;
 /* font-size: 22px; */
 /* width: 100%; */
  /* float: right; */
  transition: all 0.25s ease-in-out;
}

@media (max-width: 992px) {
  .sidenav {
    min-width: 150px;
    z-index: 10;
    --first-col-width: 0;
    /* box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); */
    box-shadow: 20px 0 100px #000;
    transition: all 0.25s ease-in-out;
  }

  #page-content {
    grid-column: 1 / 5!important;
  }
  .page-header {

  }

  .sidenav-minimized-icon {
    position: fixed;
    transition: all 0.25s ease-in-out;
  }

  .sidenav-header-minimized {
    /* font-size: 64px!important; */
    /* display: inline-block; */
    margin: 10px 0 0 10px!important;
    transition: all 0.25s ease-in-out;
  }
}

.sidenav-header:hover {
  cursor: pointer;
}

.sidenav-header-minimized {
  font-size: 22px;
  display: flex;
  margin: 0 auto 1vw auto;
}

.sidenav-header-minimized:hover {
  cursor: pointer;
}

.sidenav-list {
  height: auto;
  padding: 0;
  width: 100%;
}

.sidenav-list .sidenav-item {
  width: 100%;
  height: 60px;
  list-style-type: none;
  margin: 0%;
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: center;
  align-items: center;
}

.sidenav-list .sidenav-item:hover {
  cursor: pointer;
  background-color: var(--primary-color-light);
  color: var(--secondary-color);
}


.sidenav-list a.active .sidenav-item {
  background-color: var(--primary-color-light);
  color: var(--secondary-color);
}


.sidenav-item .sidenav-icon {
  flex: 30%;
  display: grid;
  place-items: center;
  /* padding-right: 10%; */
}
.sidenav-item .sidenav-title {
  flex: 70%;
}

#page-content {
  /* margin-left: 19%;
  margin-top: 2%; */
  margin-top: 2vw;
  grid-row: 1 / 4;
  grid-column: 2 / 5;
  transition: all 0.25s ease-in-out;
  margin: 2vw 3vw 0 3vw;
  
}

.login-content {
  display: flex;
}

.sidenav-logout {
  position:absolute;
  bottom: 1%;
}

/*-----------------
	Slideshow Style
-----------------------*/

.slides-container
{
  margin: 0 auto 0 auto;
  background-color: var(--primary-color);
  border-radius: 15px;
  border: 5px solid black;
  padding: 30px 30px 30px 30px;
  background-size: 150px;
  width: 80%;
}

.embla {
  overflow: hidden;
  margin: 0 auto 0 auto;
  display: grid;
  max-width: 100%;
  max-height: 100%;  
  place-items: center;
  place-content: center;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  background-color: black;

}

.embla-viewport {
  grid-area: 1 / 1 / 1 / 1;
}

.embla-container {
  display: flex
}
.embla-slide {
  flex: 0 0 100%;
  min-width: 0;
  position: relative;
}

.embla-slide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}


.embla-prev, .embla-next {
  background: none;
  background-color: rgba(0, 0, 0, 0.9);
  border: none;
  color: white;
  border-radius: 50%;
  border: var(--primary-color-lighter) solid 3px;
  opacity: 0.25;
  font-size: 2.5rem;
  cursor: pointer;
  z-index: 1;
  /* padding: 10px; */
  /* padding: 500px; */
  grid-area: 1 / 1 / 1 / 1;
  display: flex;
  place-items: center;
  width: 50px;
  height: 50px;
  min-width: 20px;
  min-height: 20px;

}

.embla-prev:hover, .embla-next:hover {
  opacity: 1.0;
}
.embla-prev {
  place-self: center left;
  margin-left: 3px;
}
.embla-next {
  place-self: center right;
  margin-right: 3px; 
}

@media (max-width: 992px) {
  /* .embla {
    max-width: 95%;
    min-width: 90%;
    max-height: 95%;
  } */
  
  .slides-container {
    width: 100%;
    padding: 20px 20px 20px 20px;
  }

  .embla-prev, .embla-next {
    /* font-size: 30px; */
    font-size: 1.75rem;
    width: 35px;
    height: 35px;
    border: var(--primary-color) solid 2px;
  }
}
@media (max-width:  575.98px) {
  .slides-container {
    width: 100%;
    padding: 10px 10px 10px 10px;
  }
  .embla-prev, .embla-next {
    font-size: 1.25rem;
    width: 25px;
    height: 25px;
    border: var(--primary-color) solid 2px;
    padding: 1px;
  }
}

/*-----------------
	Google Slides Style
-----------------------*/
.google-slides-container-background {
  /* background-color: var(--primary-color);
  border-radius: 15px;
  border: 5px solid black;
  padding-top: 20px;
  padding-bottom: 20px;
  background-size: 150px;
  width: 90%;
  margin: 0 auto 0 auto; */
}

.google-slides-container {
  position: relative;
  width: 90%;
  padding-top: 52%;

  overflow: hidden;
  margin: 0 auto 0 auto
}

.google-slides-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}



/*-----------------
	Lesson
-----------------------*/

.lesson-learning-objectives {
  list-style-type: disc !important;
  margin-bottom: 2vw;
}

.lesson-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 100px;
  overflow: wrap;
  /* margin: 1vw 3vw 1vw 3vw; */
  place-content: center;
}

.lesson-details {
  grid-area: 1 / 1 / 1 / 3;
}

.lesson-learning-objectives {
  grid-area: 1 / 3 / 1 / 3;
  border: 2px solid #a4a4a4;
  border-radius: 15px;
  padding: 10px;
  font-size: 0.85rem;
}

@media (max-width:  575.98px) {
  .lesson-content {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    grid-gap: 10px;
  }

  .lesson-details {
    grid-area: 1 / 1 / 1 / 1;
  }
  .lesson-learning-objectives {
    grid-area: 2 / 1 / 2 / 1;
  }
}
.lesson-quiz-button-container {
  display: flex;
  justify-content: flex-end;
}

.lesson-previous-button-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.lesson-quiz-button {
  /* bottom: 2%;
  right: 5%; */
  width: 200px;
  color: white;
  transition: all 0.25s ease-in-out;
  padding: 1px;
  margin: 10px 0 10px 0;
  display: flex;
  padding: 5px;
  place-items: center;
  
}

.lesson-quiz-button .lesson-quiz-button-icon {
  /* padding-left: 20px; */
  flex: 0%;
  display: inline-block;
}

.lesson-quiz-button .lesson-quiz-button-title {
  flex: 70%;
  display: inline-block;
  padding-right: 15px;
  /* padding-right: 20px; */
  /* padding-right: 20px; */
}

/*-----------------
	Lesson Article
-----------------------*/
.lesson-article {

}

.lesson-article-title {
  text-align: center;
  text-decoration: underline;
  padding-bottom: 20px;
}

.lesson-article .lesson-learning-objectives {

  margin: 0 20%;
}

.lesson-articles-sections {
  margin-top: 10px;
  padding: 0 20%;
}

.lesson-article-section-header {

}

.lesson-article-section-paragraph {
  margin-bottom: 0.75rem;
  font-size: 1.25rem;
}

/* .lesson-article-section-paragraph li {
  font-size: 2.0rem;
} */

.lesson-article-section {
  padding-top: 20px;
}

@media (max-width: 992px) {
  .lesson-article .lesson-learning-objectives {
    margin: 0 2%;
  }
  .lesson-article-section-header {
    text-align: center;
  }
  .lesson-articles-sections {
    padding: 0 2%;
  }
  .lesson-article-section-paragraph {
    margin-bottom: 1.0rem;
    font-size: 1.25rem;
  }
}

/*-----------------
	Quiz
-----------------------*/

.quiz {
  display: grid;
  grid-template-rows: 0.25fr 1fr auto;
  grid-template-columns: 1fr;
  place-content: center;
  place-items: center;
  grid-gap: 30px;
  grid-auto-flow: row;
  width: 60%;
  margin: 0 auto 0 auto;
}

.quiz-question {
  font-size: 2rem;
  font-weight: bold;
  grid-area: 2 / 1 / 2 / 1;
  text-align: center;
  /* margin: 0 0 0 auto !important; */
}

.quiz-answers {
  grid-area: 3 / 1 / 3 / 1;
  font-size: 1.2rem;
  margin-bottom: 100px;
}

.quiz-answers-item {
  list-style-type: none;
  /* margin-bottom: 20px; */
  padding: 10px;
  cursor: pointer;
}

.quiz-answers-item label {
  cursor: pointer;
}


.quiz-answers-item button {
  border: 1px solid #000;
  margin-right: 20px;
  border-radius: 50%;
  place-items: center;
  height: 30px;
  width: 30px;
  font-size: 1rem;
  transition: all 0s ease !important;
  background-color: #fafafa;

}

.quiz-progress-bar {
  grid-area: 1 / 1 / 1 / 1;
}

.quiz-button-selected button {
  background-color: var(--primary-color-light)!important;
  outline: 3px solid var(--primary-color-dark) !important;
  color: #ffffff;
  border: none;
  /* 099dff */
}

.quiz-button-selected {
  background-color: rgba(var(--primary-color-lighter-rgb), 0.5);
}

.quiz-prev-button, .quiz-submit-button {
  position: fixed;

  transition: all 0.25s ease-in-out;
}

.quiz-submit-button {  
  bottom: 2%;
  right: 5%;
}

.quiz-prev-button {
  bottom: 2%;
  right: calc(13vw - var(--first-col-width) + 70vw);
}

@media (max-width: 992px) {
  .quiz {
    width: 100%;
  }
  .quiz-prev-button, .quiz-submit-button {
    font-size: 0.75em;
  }
  .quiz-prev-button {
    right: 80%;
  }
}


@media (max-width: 575.98px) {
  .quiz-prev-button, .quiz-submit-button {
    font-size: 0.5em;
  }
  .quiz-prev-button {
    /* right: 70%; */
  }
}

/*-----------------
	Top Bar
-----------------------*/
.top-bar {
  position: fixed;
  width: 100%;
  /* grid-row: 1 / 1;
  grid-column: 2 / 5; */
  background-color: #000;
  display: grid;
  grid-column: 1fr 1fr 1fr 1fr;
  z-index: 20;
}

.top-bar-logout {
  grid-column: 4 / 4;
  color:white;
}

/*-----------------
	Course Details Page
-----------------------*/
.unit-container {
  border: 2px solid #a4a4a4;
  background-color: #ffffff;  
  border-radius: 10px;
  margin: 20px auto 20px auto;
  margin-bottom: 10px;
  padding: 10px;
  height: 100%;
  width: 60%;
  /* display: table; */
}

.unit-header {
  text-align: center;
}

.unit-header a {
  /* text-decoration: none; */
  margin-right: auto!important;
  color: inherit;

}

.unit-header a:hover {
  text-decoration: underline;
}

.lesson-name-link {
  list-style-type: none;
}

.course-details-container {
  width: 90%;
  margin: 0 auto 0 auto;
}
.course-details-image img{
  border-radius: 30px;
  transition: transform 0.25s ease-in;
}

.course-details-image:hover img {
  transform: scale(1.1);
}

.course-details-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-bottom: 40px;
  width: 80%;
  margin: 0 auto 0 auto;
}

.course-details-description {
}

.course-details-title {
  text-decoration: underline;
}

.blog-image img{
  margin: 0 auto 0 auto !important;
  text-align: center;
  width: 100%;
}
@media (max-width: 992px) {
  .unit-container {
    width: 90%;
  }
}

@media (max-width:  575.98px) { 
  .unit-container {
    width: 100%;
  }
}

/*-----------------
	Course Card
-----------------------*/


.course-cards-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  transition: all 0.25s ease-in;
}

.course-card {
  display: flex;
  flex-direction: column;

  width: 375px;
  height: 700px;
  background: var(--container-background-color);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 10px;
  gap: 5px;
  transition: all 0.25s ease-in-out;
}
.course-card-img {
  /* clip-path:inset(0); */
  width: 350px; /* Set your desired container width */
  height: 350px; /* Set your desired container height */
  overflow: hidden;
  position: relative;
  margin: auto;
  border-radius: 15px;
  transition: all 0.25s ease-in-out;
}

.course-card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.course-card-title {
  padding: 1px 10px 1px 10px;
  font-weight: 600;
  text-align: center;
  font-size: 1.1rem;
}

.course-card-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 5px;
  /* margin-top: 10px; */
  height: 50px;
  padding: 10px;
}

.course-card-details {
  padding: 10px;
  height: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.80rem;
  margin-bottom: 20px;
}


@media (max-width:  575.98px) {
  .course-cards-container {
    justify-content: center;
  }
  .course-card {
    width: 325px;
    height: 600px;
  }
  .course-card-img {
    width: 300px;
    height: 300px;
  }

  .course-card-button-container {
    padding: 0px;
  }
}
/*-----------------
	Unit Details Page
-----------------------*/
.unit-page-lessons {
  list-style-type: none;
}

/*-----------------
	Gradebook Main Default Style
-----------------------*/
.gradebook-root-list {
  list-style: none;
}

.gradebook-root-class-item {
  
}

/*-----------------
	Teacher Settings Style
-----------------------*/
.toggle-lesson-container {
    padding-left: 40px;
}

.toggle-lesson, .toggle-unit {
  white-space: nowrap;
}

.toggle-lesson label, .toggle-unit label {
  padding-left: 10px;
}

/* .toggle-lesson input, .toggle-unit input {
  width: 17px;
  height: 17px;

} */

.toggle-unit label {
  font-weight: 500;
  font-size: 1.1rem;
}

.toggle-class-container {
  margin-left: 20px;
}

.toggle-class-component {
  margin-top: 20px;
}

.export-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/*-----------------
	Table Default Style
-----------------------*/
.gradebook-table-container > * {
  width: 70vw;
  margin: 0 auto 0 auto;
}

.table-label {
  margin: 0 auto 0 auto;
  text-align: center;
}

.table-container {
  padding: 0 10px 10px 10px;
  overflow: auto; 

}

.table-container table {

  border-collapse: collapse;
  width: 100%;
}

.table-container table td, .table-container table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.table-container table tr:nth-child(even){background-color: #f2f2f2;}

.table-container table tr:hover {background-color: #ddd;}

.table-container table th, .table-container tfoot td {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: var(--primary-color-light);
  color: white;
  min-width: 100px;
}

.table-controls {
  /* display: block !important; */
  text-align: right;
  font-weight: 300;
  margin-top: px;
}

.table-control-button {
  background: none;
  border: none;
  color: black;
  font-weight: bold;
  margin: 0 5px 0 5px;
  /* padding: 3px 5px 3px 5px; */
  width: 35px;
  text-align: center;
  transition: all 0.1s ease;
  
}

.table-control-button:hover {
  background-color: #d3d3d3;
  border-radius: 20%;
  outline: 1px solid #a4a4a4;
}

.table-control-button:disabled {
  color: #a4a4a4;
  pointer-events: none;
}

.table-control-rows-per-page {
  background: none;
  border: none;
  font-weight: 300;
}

.table-control-rows-per-page-container {

  font-weight: 300;
  float: left;
}

.table-control-goto {
  margin: 0 10px 0 20px;
  
}

.table-control-goto input {
  width: 50px;
  /* background: none; */
  border: 1px solid #a4a4a4;
  border-radius: 15%;
  font-weight: 300;
}

.table-control-goto * {
  font-weight: 300;
}

@media (max-width: 992px) {
  .gradebook-table-container {
    width: 100vw;
  }

  .table-control-goto {
    display: block;
    text-align: center;
  }
  .table-control-rows-per-page-container {
    display: block;
    text-align: center;
    float: none;
  }
  .table-control-buttons {
    display: block;
    text-align: center;
  }
}


/*-----------------
	Course Code Form Style 
-----------------------*/
.course-code-container {
  margin: 0 auto 0 auto;
  text-align: center;
  margin-bottom: 5px;
}

.course-code-open-button {
  margin-top: 30px;
}

.course-code-input-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  /* flex-direction: row; */
  flex: 100%;
}

@media (max-width: 992px) {
  .course-code-input-container label p  {   
    display: block;
  }
}


/*-----------------
	Modal Style 
-----------------------*/
.c-modal-background {
  width: 100%;
  height: 100%;
  background-color: rgba(200, 200, 200, 0.65);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  bottom: 0%;
  left: 0;
  transition: all 0.25s ease-in-out;
  left: 0%;
  /* left: 50%; */
  /* transform: translate(-50%, 50%); */
  /* padding-left: 150px; */
}

.c-modal-container {

  border-radius: 12px;
  background-color: white;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.35);
  display: flex;
  flex-direction: column;
  padding: 5px;
  transition: height 0.15s ease-in-out;
  /* margin: 0 auto 0 auto; */

}

.c-modal-container .c-modal-title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.c-modal-close-btn-container {
  display: flex;
  justify-content: flex-end;
}

.c-modal-close-btn-container button {
  background-color: #dc143c;
  color: white;
  border-radius: 5px;
  width: 35px;
  height: 35px;
  border: none;
  font-size: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.15s ease-in-out;
}

.c-modal-close-btn-container button:hover {
  background-color: #f1627e;
}

.c-modal-close-btn-container button:active {
  background-color: #b21031;
}


/* 
.c-modal-close-btn {
  background-color: crimson; 
} */

.c-modal-container .c-modal-body {
  flex: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  text-align: center;
  
}

.c-modal-container .c-modal-footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding-left: 20px;
  padding-right: 20px;
}
 

/*-----------------
	Register Class Modal Style 
-----------------------*/
.c-register-class-footer-course-name {
  font-weight: 600;
}

.register-class-container {
  margin: 0 auto 0 auto;
  text-align: center;
  margin-bottom: 5px;
}

.register-class-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.register-class-inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;

}

.register-class-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* flex-direction: row; */
  flex: 100%;
}

.register-class-form-button {
  align-self: center;
  margin-top: 10px;
}

@media (max-width: 992px) {
  .register-class-input-container label p  {   
    display: block;
  }
}

/*-----------------
	Progress Bar Style 
-----------------------*/
.progress-bar-container {
  width: 100%;

}

.progress-bar {
  background-color: #d3d3d3;
  border-radius: 15px;
}

.progress-bar-fill {
  width: 0%;
  height: 30px;
  background-color: var(--secondary-color);
  border-radius: 15px;
  transition: width 0.25s ease-in;
}

.progress-bar-title
{
  background-color: none;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
  padding-bottom: 3px;
}


/*-----------------
	Circle Progress Bar Style 
-----------------------*/
.circle-progress-bar-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.circle-progress-bar {
  border-radius: 50%;
  width: 300px;
  height: 300px;
  transition: all 0.0 ease-in;
}

.circle-progress-bar-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 1.75rem;
  font-weight: 600;
}


/*-----------------
	Quiz Results Style 
-----------------------*/
.quiz-results-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quiz-results-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  width: 50%;
  text-align: center;
}

@media (max-width: 992px) {
  .quiz-results-container {
    width: 90%;
  }
}

@media (max-width:  575.98px) {
  .quiz-results-container {
    width: 100%;
  }
}


/*-----------------
	Page Not Found Style 
-----------------------*/
.page-not-found {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.page-not-found img {
  max-width: 600px;
  max-height: 600px;
  width: auto;
  height: auto;
  margin-bottom: 30px;
}

.page-not-found p {
  font-size: 1.5rem;
}

/*-----------------
	Sign up form Style 
-----------------------*/
.sign-up-form-container {

  padding-bottom: 40px;
  /* margin: auto 0 auto 0;
  padding: 10px; */

}

.sign-up-account-type {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  /* margin-top: 100px; */
  /* align-self: flex-start; */
  /* padding-bottom: 100px; */
}

.sign-up-teacher-code {
  
}

.sign-up-account-type button {
  width: 80%;
  font-size: 24px;
}

.sign-up-form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  
  flex-direction: column;
}





/*-----------------
	Activity History Style 
-----------------------*/
.activity-history-container
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;

  padding: 15px;

}
.activity-history-activities {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 7px;
}

.activity-no-info {
  font-weight: 300;
}

.activity-info-container {
  display: flex;
  justify-content: space-between;
}

.activity-info-title {
  font-weight: 500;
}
.activity-info-date {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 0.90rem;
  padding-left: 10px;
  font-weight: 300;
}

/*-----------------
	Unity Activity History Style 
-----------------------*/
.unity-activity-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;

}


.unity-activity-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}


/*-----------------
	Python Activity Style 
-----------------------*/
.python-activity-page {
  display: flex;
  flex-direction: column;
}
.python-activity-description {
  padding: 0 4rem 2rem 1.0rem;
}

.python-interpreter {
  width: 100%;
  margin-bottom: 25px;
}

.coding-activity-description {
  width: 100%;
  overflow: scroll;
  border: 10px solid rgba(255, 255, 255, 0);
}

.code-editor {
  width: 100%;
  height: 70%;
}

.xterm-viewport::-webkit-scrollbar {
  background: #313131;
  width: 8px;
}



.xterm-viewport::-webkit-scrollbar-thumb {
  background: rgb(100, 100, 100);
  border-radius: 10px;
}

.code-terminal
{
  width: 100%;
  height: 30%;
  border-top: 2px solid rgb(46, 45, 45);
}

.code-terminal > div {
  height: 100%;
}

.code-execute-button {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.code-snippet {
  margin: 0.5rem 0;
}

.code-instructions-container {
  margin-top: 20px;
}

.code-instructions-header {
  display: flex;
  flex-direction: row;
  font-size: 1.2rem;
  align-items: center;
  gap: 7px;
  margin-bottom: 10px;
}

.code-instructions-header-icon {

}

.code-instructions-header-text{

}

.code-instruction-item-container {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.code-instruction-item-icon {
 margin-right: 15px;
 font-size: 1.2rem;
 text-align: center;
}

.code-instruction-item-icon-complete {
  color: #5cb85c;
}

.code-instruction-item-idx {
  padding-right: 5px; 
}

.inline-code {
  background-color: #eae9ed;
  white-space: normal;
  padding: 0.25rem;
  margin: 0 .0625rem;
  border-radius: .125rem;
  color: #15141f;
  vertical-align: baseline;
}

 .ide-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

/*-----------------
	Dual Panel Style 
-----------------------*/
.resizable-dual-panel {
  display: flex;
  flex-direction: row; 
  height: 82vh;
  width: 100%;
  max-width: calc(95vw - var(--first-col-width));
  transition: all 0.15s ease-in-out;
}

.resize-bar {
  background-color: #2796fd;
  width: 0.5%;
  cursor: ew-resize;
  height: 97%;
  margin: auto;
  /* border: solid blue 1px; */
  border-radius: 5px;
  opacity: 0;
  transition: all .2s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.resize-bar:hover {
  opacity: 1;
}

@media (max-width: 992px) { 
  .resizable-dual-panel {
    flex-direction: column;
    align-items: center;
    gap: 5px;
    min-height: 1000px;
  }

}

/*-----------------
	Analytics Style 
-----------------------*/
.analytics-single-metric-box {
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* border-right: 1px solid rgba(110, 110, 110, 0.247); */


}

.analytics-single-metric-box-header {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  font-size: 1.6rem;
  
}

.analytics-metric-box-icon {
  padding-top: 5px;
}

.analytics-single-metric-box-title {
  font-size: 1.1rem;
  text-overflow: wrap;
  max-width: 200px;
  text-align: center;
}

.analytics-dashboard-header-container {
  
}

.analytics-dashboard-header {
  width: 1000px;
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background: var(--container-background-color);
  border-radius: 15px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.analytics-text {

}

/*-----------------
	Teacher Dashboard Style 
-----------------------*/

.teacher-dashboard-analytics-header {
  text-align: center;
}

.teacher-dashboard-student-overview {
  width: 1000px;
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.teacher-dashboard-avg-grade {
  margin: auto 0;
  flex-basis: 30%;
}

.teacher-dashboard-graphs {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.teacher-dashboard-quizzes-per-day {
  width: 950px;
  flex-basis: 60%;
}


/*-----------------
	AI LLM Generate Style 
-----------------------*/
.ai-tools-dashboard-container {
  display: flex;
  flex-direction: row;
  /* flex-gap: 1px; */
  gap: 20px;
}

.ai-tools-dashboard-header {
  text-align: center;
  margin-bottom: 40px;
}

.ai-tools-select-box {
  display: flex;
  color: #333;
  background: var(--container-background-color);
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 15px;
  width: 400px;
  height: 100px;
  cursor: pointer;
  transition: box-shadow 0.15s ease-in-out;
}
.ai-tools-select-box:hover {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.4);
}

.ai-generate-form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}

.ai-tools-select-box-icon {
  margin: auto 0;
  padding: 0 20px 0 5px;
}

.ai-tools-select-box-info-container {
  display: flex;
  flex-direction: column;
}

.ai-tools-select-box-title {
  font-weight: 500;
}
.ai-tools-select-box-title p {
  margin-bottom: 5px;

}

.ai-tools-select-box-description {
  font-size: 14px;
  font-weight: 300;
  margin: 0;
}

.ai-tools-select-box-description p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ai-tool-container {

}

/*-----------------
	AI LLM Form Style 
-----------------------*/
.ai-tool-form-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 1000px;
  align-items: center;
}

.ai-tool-form-header {
  text-align: center;
}

.ai-tool-form-header p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ai-tool-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.ai-tool-form-input input, .ai-tool-form-input textarea, .ai-tool-form-input select {
  width: 800px;  
}
.ai-tool-form-header p {
  font-weight: 300;
}

.ai-tool-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  height: 50%;
  gap: 50px;
  width: 1000px;
  margin: 0 auto;

}

.ai-tool-loading-oval {
  margin: 0 auto;
  width: 200px;
}

/*-----------------
	AI LLM Result Style 
-----------------------*/
.ai-result-header {
  display: flex;
  flex-direction: row;
  width: 1000px;
  margin: 0 auto 20px auto;
  text-align: center;
  flex-flow: space-between;
}

.ai-result-header-button {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  /* flex: 25%; */
  cursor: pointer;
}
.ai-result-header-button p {
  margin: 0;
}

.ai-result-header-button-icon {
  vertical-align: middle;
}

.ai-result-header-button:hover {
  color: var(--secondary-color);
  
}

.ai-result-header-title {
  flex: 50%;  
}

.ai-result-header-side {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 25%;
  gap: 20px;
}

.ai-result-container {
  width: 1000px;
  margin: 0 auto;
}

.ai-quiz-question-container {
 margin-bottom: 20px; 
}

.ai-quiz-options {
  margin-bottom: 10px;
}

.ai-quiz-option {
  padding-left: 20px;
  margin-bottom: 5px;
}

.ai-quiz-answer {
  font-weight: 600;
}

.ai-tool-generated-item h5 {
  font-weight: 600;
}

.ai-tool-generated-item ul {
  padding-left: 30px;
}
.ai-tool-generated-header {
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
}

.ai-tool-generated-item-content {
  padding-left: 30px;
}

/* .ai-tool-generated-item-str {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ai-tool-generated-item-str p {
  margin: 0;
  padding: 0;
  text-align: c;
} */